import _ from 'lodash';
import { dispatch } from 'rfx-core';

import { getChildLogger } from '#/shared/utils/client.logger';

class Uploader {
  constructor({ bucket, maxFileSize = 150, fileTypes, errors = {} }) {
    this.maxFileSize = maxFileSize;
    this.bucket = /development/i.test(process.env.NODE_ENV) ? 'DEV' : bucket;

    this.log = getChildLogger(`Uploader.${this.bucket}`);

    if (_.isArray(fileTypes)) {
      this.fileTypesRegex = new RegExp(fileTypes.join('|'), 'i');
    } else if (_.isString(fileTypes)) {
      this.fileTypesRegex = new RegExp(fileTypes, 'i');
    } else if (fileTypes instanceof RegExp) {
      this.fileTypesRegex = fileTypes;
    } else {
      this.log.warn(
        'Unknown or No File Types specified. Using ".*" which will allow any filetype',
      );
    }

    this.errors = errors;
  }

  bucket = 'DEV';

  maxFileSize = 150;

  log = getChildLogger('utils.s3uploader');

  fileTypesRegex = /.*/;

  errors = {};

  getSignedUrl = async (file, cb) => {
    this.log.debug('[getSignedUrl] signing file', { file });
    try {
      const skipTimestamp = /pdf/i.test(file.type);

      const params = {
        query: {
          action: 'sign',
          bucketService: this.bucket,
          contentType: file.type,
          filename: file.name,
          skipTimestamp,
        },
      };
      const res = await dispatch('s3.sign', { params });

      this.log.debug('[getSignedUrl] Signed file', { res });

      if (_.isFunction(cb)) {
        cb(res);
      }

      return res;
    } catch (err) {
      this.log.error('Error Getting Signed Url', err);
      throw err;
    }
  };

  selectFile = async (file, cb) => {
    if (!file) {
      return false;
    }

    this.log.debug('[selectFile] signing file', { file });
    const fileType = _.get(file, 'type');
    const fileSize = _.get(file, 'size');
    if (!this.fileTypesRegex.test(fileType)) {
      alert(
        this.errors.unsupported ||
          'Unsupported File Type, please upload an image.',
      );
      return false;
    }
    if (fileSize / 1000000 > this.maxFileSize) {
      alert(
        this.errors.maxSize ||
          `File too large. Files must be smaller than ${this.maxFileSize}mb`,
      );
      return false;
    }

    const res = await this.getSignedUrl(file, cb);

    this.log.debug('[selectFile] Got Signed URL', { res });

    return res;
  };
}

export default Uploader;
