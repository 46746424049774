import type {
  IUser,
  CoreCompany,
  ICompany,
  CompanyStatusRecord,
  IBaseItem,
  ISite,
  IZone,
} from '@shiftsmartinc/shiftsmart-types';

import {
  observable,
  computed,
  action,
  reaction,
  runInAction,
  set,
  IObservableArray,
} from 'mobx';
import { dispatch } from 'rfx-core';
import moment from 'moment';
import _ from 'lodash';
import BBPromise from 'bluebird';
import uuid from 'uuid';
import { datadogRum } from '@datadog/browser-rum';

import { app, service } from '#/shared/app';
import { parseQueryString } from '#/shared/utils/parseQueryString';
import { getChildLogger } from '#/shared/utils/client.logger';

const LOG_PREFIX = `stores.auth${
  /SERVER/i.test(global.type) ? `[${global.TYPE}]` : `[${uuid.v4()}]`
}`;
const fileLog = getChildLogger(LOG_PREFIX);

/**
 * # Auth
 *
 * This store is responsible for storing authentication and session information.
 *
 * ## Related and Overlapping Stores
 * There are a few stores with logic that is closely tied with the logic contained
 * here. They have been separated out in an effort to keep related-logic in a single
 * file and to avoid overlap. However, the lines between these stores are blurry at
 * best and there may be a better way to manage the separation.
 *
 * - *authManagement* Responsible for forgotten, reset and change password logic
 * - *abilities* Handles the CASL based "authorization" part of "auth"
 * - *prefs* Stores prefs in localStorage to preserve settings and config from day to day
 *
 * ## How does the app get started?
 *
 * So you found yourself here trying to understand the full app lifecycle. How does the
 * app get authenticated, and how do the different stores relate to one another.
 *
 * ### Full Session Init Logic
 *
 * 1. authenticate
 * 1. doAuth
 * 1. exchangeAuthForUser
 * 1. setupSession
 *     1. updateUser
 *     1. initCompanies
 *     1. abilities.initSession
 *     1. initChat
 *     1. initGoogleAnalytics
 *     1. checkPassword
 * 1. return auth'd user
 */
export default class AuthStore {
  redirect = '/';

  jwt: string | null = null;

  @observable
  user: Partial<IUser> &
    Required<
      Pick<
        IUser,
        | 'roles'
        | 'displayName'
        | 'email'
        | 'phoneNumber'
        | 'profileImageURL'
        | 'companyStatus'
        | 'companies'
      > &
        IBaseItem
    > = {
    _modelName: 'user',
    companies: [],
    companyStatus: [],
    createdAt: null,
    displayName: null,
    email: '',
    modelName: 'user',
    phoneNumber: '',
    profileImageURL: '',
    roles: [],
    updatedAt: null,
    uuid: null,
  };

  @observable
  company: ICompany | Record<string, never> = {};

  /**
   * Hash of "dispose" functions, eg: event listener disposers
   * Reserved for future use.
   */
  @observable
  disposers = {};

  @observable
  isOTP = false;

  // For OTP, where the email needs to be sent to prevent feathers from erroring
  @observable
  enteredEmail = '';

  @observable
  enteredPassword = '';

  /**
   * ### siteCount
   *
   * This will decide location will be shown or not
   *
   */
  @observable
  siteCount = 0;

  /** ## init
   * @summary Called by:
   *  - `rfx-core.initialzeStores`
   *  - `stores.inject({})` in client.jsx
   */
  init() {
    fileLog.getChildLogger(`[init]`).debug('START');
    this.registerLogoutListener();
  }

  /** ## authenticate
   * @summary Entry-Point for all Auth
   *
   * ### Called From:
   * - this.init()
   * - src/web/bootstrap.js
   * - src/web/client.jsx
   *
   * ### logic Flow
   * 1. As the request is made, the app goes thru one of the main entrypoints for init.
   *    As of this writing (2021-03-26), these include:
   *        - bootstrap.js (ssr)
   *        - client.jsx (browser)
   * 1. These entrypoints each call the `auth.authenticate` method
   * 1. If `authenticate` (or `doAuth`) has already been called, a cached promise will
   *    be returned to the caller instead.
   * 1. Authenticate then loads a token from the user's browser (or other means; see
   *    @feathersjs/authentication for more).
   * 1. If a token is available, we call `auth.doAuth` to validate the token with the server
   */
  async authenticate(): Promise<void> {
    const log = fileLog.getChildLogger(`authenticate`);

    try {
      const accessToken = await app().authentication.getAccessToken();

      const token = accessToken ?? this.getToken();

      // Keep the token up-to-date
      this.setToken(token);

      if (token) {
        if (!accessToken) {
          log.debug('setting access token for authentication service');
          app().authentication.setAccessToken(token);
        }

        log.debug('Calling JWT Auth with Token.');
        await this.doAuth({ accessToken: token, strategy: 'jwt' });

        const path =
          (/CLIENT/i.test(global.TYPE) && window?.location?.pathname) ||
          dispatch('app.getPathname');

        if (this.user.uuid && /auth$/i.test(path)) {
          this.redirectAfterLogin();
        }
      }
    } catch (err) {
      log.error('Auth Failed', err);
    }
  }

  // #region Auth Server Communication
  _authP = null;

  /**
   * ## doAuth
   * This method is the entry point for the varous authentication methods. The main two are:
   * - logging in via a username/password strategy
   * - validating an existing JWT token
   *
   * @param {*} authStrategyOptions
   * @return {*}
   * @memberof AuthStore
   */
  async doAuth(authStrategyOptions) {
    const log = fileLog.getChildLogger(`doAuth`);
    if (!this._authP || this.isOTP) {
      log.debug('Initializing new Auth Promise');
      // TODO: How does this assignment work for the error conditions below, which reassign `this._authP`?
      this._authP = await this.exchangeAuthForUser(authStrategyOptions);
    } else {
      log.debug('Using existing Auth Promise');
    }

    return this._authP;
  }

  /**
   * ## exchangeAuthForUser
   * This method does the actual validation of credentials (username/password _or_ JWT) and
   * then initalizes the session for the user (assuming the credentials are valid)
   *
   * @param {*} authStrategyOptions
   * @return {*}
   * @memberof AuthStore
   */
  async exchangeAuthForUser(authStrategyOptions) {
    const log = fileLog.getChildLogger(`exchangeAuthForUser`);
    try {
      const authResponse = await this.reAuthenticate();
      let user = authResponse?.user;
      let accessToken =
        authResponse?.accessToken ??
        (await app().authentication.getAccessToken().catch(_.noop));

      if (!user) {
        log.debug(
          `Authenticating with auth strategy "${authStrategyOptions.strategy}"`,
        );
        const response = await app().authenticate(authStrategyOptions);
        if (response.isWorker) {
          // If worker, display message about using mobile app and do not proceed
          localStorage.removeItem('feathers-jwt');
          dispatch(
            'ui.snackBar.error',
            'To log in as a Partner, please use our mobile app on iOS or Android.',
          );
          return null;
        }

        if (
          !_.intersection(response.user?.roles, [
            'admin',
            'super-admin',
            'employer',
          ])
        ) {
          // If the user doesn't have the correct roles to access the empoyer portal, display message and do not proceed
          localStorage.removeItem('feathers-jwt');
          dispatch(
            'ui.snackBar.error',
            'You do not have access to the Employer Portal.',
          );
          return null;
        }

        if (response.user) {
          user = response.user;
          accessToken = response.accessToken;
        } else if (response.waitingForOTP) {
          dispatch('ui.auth.toggleSection', 'otp');
          // Required since feathers adds this localStorage item  by default and it causes issues for MFA
          localStorage.removeItem('feathers-jwt');
          this.isOTP = true;
        }
      }

      if (user && accessToken) {
        this.setToken(accessToken); // TODO: Still necessary for the `this.jwt` val (i think)
        await this.setupSession(user);
      }
    } catch (err) {
      if (!/Invalid login/i.test(err.message)) {
        log.error('Error in auth: ', err);
      }
      this._authP = null;

      await this.handleError(err).catch((e) => {
        log.debug('No special Error handling found. Logging Out User', e);

        // What should we do here? If the error was handled, `logout` was called.
        // If not, should we be logging out?
        return this.logout();
      });
      if (/local|conditional-mfa|workos/i.test(authStrategyOptions.strategy)) {
        throw err;
      }

      return null;
    }

    log.debug('Resolving with User', { user: this.user });
    return this.user;
  }

  async reAuthenticate() {
    const log = fileLog.getChildLogger('reAuthenticate');
    try {
      return await app().authentication.reAuthenticate();
    } catch (error) {
      log.debug('reauthentication failed');
    }
    return undefined;
  }

  /**
   * ## AuthStore.setupSession
   * This is the meat of it, and probably where we should focus some attention on cleaning up
   * and or optimizing what exactly we load. Once we have a user object from the exchangeAuthForUser
   * method, we go thru the following steps:
   * - Call `auth.updateUser` to set the "Logged in User"
   * - Call `auth.initCompanies` calls `prefs.setup` and load Available companies for the user
   * - Call `abilities.initSession` to initialize what the user is and is not authorized to do (@see CASL)
   * - Initializes Chat and Analytics
   * - Checks with the @see AuthManagementStore to check if the user's password needs to be changed
   *
   * @param {*} user
   * @memberof AuthStore
   */
  async setupSession(user) {
    const log = fileLog.getChildLogger(`setupSession`);

    log.debug('START', { user });

    if (_.isEmpty(user?.uuid)) {
      throw new Error('Incomplete Authentication Response');
    }

    this.updateUser(user);

    await this.initCompanies(this.user);
    await dispatch('abilities.initSession', {
      company: this.company,
      user: this.user,
    });

    log.debug('Initializing Dependent Services');

    await BBPromise.all([
      this.initChat(this.user, global.TYPE),
      this.initAnalytics(this.user),
      dispatch('authManagement.checkPassword'), // TODO: Also handled in `redirectAfterLogin`
    ]);

    log.debug('User Session is Configured', {
      extra: { companyId: this.company?.uuid, userId: this.user.uuid },
    });
  }

  // #region Auth Lifecycle

  /**
   * ## login
   * Takes in an email address and password and attempts to exchange it for a
   * valid JWT token.
   *
   * @param {*} { email, password }
   * @return {*}
   * @memberof AuthStore
   */
  @action
  async login({ email, password, otp }) {
    const log = fileLog.getChildLogger(`login`);
    log.debug('START');

    try {
      if (email && password) {
        await this.doAuth({
          email,
          password,
          strategy: 'conditional-mfa',
        });
        if (this.isOTP) {
          this.enteredEmail = email;
          this.enteredPassword = password;
        }
      } else if (otp) {
        await this.finishLoginWithOTP({ otp });
      }

      // Only proceed if the response contained a valid user
      if ('uuid' in this.user) {
        log.debug('Logged in user', { user: this.user });

        this.redirectAfterLogin(this.user);

        return this.user;
      }
      return {};
    } catch (err) {
      fileLog.debug('Error in Login: ', err);
      throw err;
    }
  }

  @action async requestNewOTP() {
    const log = fileLog.getChildLogger(`requestNewOTP`);
    try {
      await app().authenticate({
        email: this.enteredEmail,
        password: this.enteredPassword,
        strategy: 'conditional-mfa',
      });
      localStorage.removeItem('feathers-jwt');
      dispatch('ui.snackBar.open', 'A new code was sent to your email');
    } catch (err) {
      log.error('Error creating new verification code', err);
      dispatch(
        'ui.snackBar.error',
        'Error when sending new verification code...',
      );
      throw err;
    }
  }

  @action
  async finishLoginWithOTP({ otp }) {
    const log = fileLog.getChildLogger('finishLoginWithOTP');
    await this.doAuth({
      email: this.enteredEmail,
      otp: otp.trim(),
      strategy: 'conditional-mfa',
    });

    if ('uuid' in this.user) {
      log.debug('Logged in user with OTP', { user: this.user });
      this.enteredEmail = '';
      this.enteredPassword = '';
      dispatch('ui.auth.toggleSection', 'signin');
    }

    return {};
  }

  /**
   * ## workosCodeLogin
   * Takes in  and attempts to exchange it for a
   * valid JWT token.
   *
   * @param {*} { code }
   * @return {*}
   * @memberof AuthStore
   */
  @action
  async workosCodeLogin(code) {
    const log = fileLog.getChildLogger(`workosCodeLogin`);

    try {
      if (code) {
        await this.doAuth({
          code,
          strategy: 'workos',
        });
      }

      // Only proceed if the response contained a valid user
      if ('uuid' in this.user) {
        log.debug('Logged in user', { user: this.user });

        this.redirectAfterLogin(this.user);

        return this.user;
      }
      return {};
    } catch (err) {
      fileLog.debug('Error in workosCodeLogin: ', err);
      throw err;
    }
  }

  /**
   * ## redirectAfterLogin
   * After login, routes the user to the originally targeted route.
   *
   * @todo This functionality is broken on account of the "page refresh after logout"
   * workaround implemented in order to clear out stores.
   *
   * @memberof AuthStore
   */
  redirectAfterLogin() {
    const log = fileLog.getChildLogger(`redirectAfterLogin`);

    const prefsHomeRoute = dispatch('prefs.getHomeRoute');
    let { redirect } = parseQueryString() || {};

    if (!redirect) {
      redirect =
        this.redirect === '/' ? prefsHomeRoute || this.redirect : this.redirect;
    }

    if (/auth/.test(redirect)) {
      log.debug('Redirecting to "Root" (/)');
      redirect = '/';
    }

    if (!dispatch('authManagement.checkPassword')) {
      return;
    }

    log.debug(`Redirecting user to "${redirect}"`);

    dispatch('routing.push', redirect);
    log.debug('Resetting redirect');

    this.redirect = '/'; // reset default redirect
  }

  @action
  async logout() {
    const log = fileLog.getChildLogger(`logout`);

    log.debug('Clearing auth promise');
    this._authP = null;
    log.debug('logout: start');
    log.debug('logging out and clearing stored values');
    await app().logout();
    runInAction(() => {
      this.clearToken();
      this.updateUser({});
      this.company = {};
    });

    datadogRum.stopSessionReplayRecording();
    datadogRum.clearUser();
  }

  /**
   * ## registerLogoutListener
   * @description Registers a mobx-reaction that is used to detect when the
   * user is logged out of the app. When the user is logged out, the browser
   * will be redirected to the `/auth` route.
   *
   * This method should only be called once.
   *
   * @memberof AuthStore
   */
  registerLogoutListener() {
    const log = fileLog.getChildLogger(`loadAuthPageOnLogout`);
    if (this.disposers.logout) {
      log.warn(
        'Logout listener has already been registered for the auth store',
      );
      return;
    }

    let prevVal;
    this.disposers.logout = reaction(
      () => this.check,
      (check) => {
        const path =
          (/CLIENT/i.test(global.TYPE) && window?.location?.pathname) ||
          dispatch('app.getPathname');

        if (
          !_.isNil(prevVal) &&
          !check &&
          check !== prevVal &&
          !/\/auth/i.test(path)
        ) {
          log.debug('Logout Detected ... Routing to `/auth`');
          dispatch('routing.push', '/auth');
          dispatch('routing.refresh', { reload: true });
        } else if (check === prevVal) {
          log.verbose(`Logged In Check is unchanged (${check})`);
        }
        prevVal = check;
        return false;
      },
    );
  }
  // #endregion Auth Lifecycle

  // #region Error Handling

  handleError(err) {
    switch (err.code) {
      case 401:
        return this.handleAuth401Errors(err, err.data);
      case 404:
        return this.handleAuth404Errors(err);
      default:
        throw err;
    }
  }

  async handleAuth401Errors(err, errData = (err && err.data) || err) {
    const log = fileLog.getChildLogger(`handle401Errors`);

    try {
      switch (errData.name) {
        case 'NotAuthenticated':
          if (/invalid signature/i.test(errData.message)) {
            log.error('Logging out due to Changed Token');
            await this.logout();
            return;
          }
          break;
        case 'TokenExpiredError':
          log.error('Logging out due to Expired Token');
          await this.logout();
          return;
        default:
          break;
      }
    } catch (e) {
      log.error('Error Handling Error', e);
      throw err;
    }

    log.debug('Re-throwing Unhandled 401 Error');
    throw err;
  }

  async handleAuth404Errors(err, errData = (err && err.data) || err) {
    const log = fileLog.getChildLogger(`handle404Errors`);
    try {
      switch (errData.name) {
        case 'NotFound':
          log.error('Logging out due to User not Found');
          await this.logout();
          return;
        default:
          break;
      }
    } catch (e) {
      log.error('Error Handling Error', e);
      throw err;
    }

    log.debug('Re-throwing Unhandled 404 Error');
    throw err;
  }
  // #endregion

  // #region JWT Handling
  // Parts of this logic may be unnecessary with Feathers 4 auth, however the
  // `this.jwt` value _is_ used with SSR state hydration.

  getToken(): string | undefined {
    if (this.jwt) {
      return this.jwt;
    }

    const storedCookie = localStorage.getItem('feathers-jwt');
    if (storedCookie) {
      return storedCookie;
    }

    return undefined;
  }

  setToken(accessToken) {
    this.jwt = accessToken;
    return !!accessToken;
  }

  clearToken() {
    this.jwt = null;
  }

  // #endregion

  // #region Profiles

  /**
   * check
   *
   * @description Check Auth (if user is logged)
   */
  @computed
  get check() {
    return !_.isEmpty(this.user?.uuid);
  }

  @computed
  get isAdmin() {
    return this.user?.isAdmin ?? _.includes(this.user.roles, 'admin');
  }

  @computed
  get isSuperAdmin() {
    return (
      this.user?.isSuperAdmin ?? _.includes(this.user.roles, 'super-admin')
    );
  }

  @computed
  get isEngineer() {
    return _.includes(this.user.roles, 'engineer');
  }

  @computed
  get isEmployer() {
    return this.user?.isEmployer ?? _.includes(this.user.roles, 'employer');
  }

  /** Flag indicating that the logged in user has a full `agent` role on their CSR */
  @computed
  get isCompanyAgent() {
    return _.includes(this.companyStatus?.roles, 'agent');
  }

  /** Flag indicating that the logged in user has the `site-manager` role on their CSR */
  @computed
  get isSiteManager() {
    return _.includes(this.companyStatus?.roles, 'site-manager');
  }

  @computed
  get userId() {
    return this.user?.uuid;
  }

  @computed
  get companyId() {
    return this.company?.uuid ?? null;
  }

  /**
   * ## companyIds
   *
   * Used to return all the parent companies id including itself
   */
  @computed
  get companyIds() {
    return this.company?.path?.split('/') ?? [];
  }

  /**
   * ## parentCompanyIds
   *
   * Used to return all the parent companies id
   */
  @computed
  get parentCompanyIds() {
    return this.companyIds.slice(0, -1) ?? [];
  }

  getParentCompanyIds() {
    return this.parentCompanyIds;
  }

  // #region Company Management

  /**
   * ### managedLocations
   *
   * A list of IAddress (ISite) saved locations that the logged-in site-manager is
   * subscribed to. The value will be null if site restrictions are not enabled for
   * the logged in user (ie: user has acces to _all_ sites)
   *
   * As of this writing, this funcitonality is exclusive to the manage sites assignments
   */
  @observable
  managedLocations = [] as IObservableArray<IZone | ISite>;

  /**
   * Computed helper function to return the UUIDs of the user's managed locations. Will
   * return null if site restrictions are not enabled for the logged in user.
   */
  @computed
  get managedLocationIds() {
    return this.managedLocations ? _.map(this.managedLocations, 'uuid') : null;
  }

  getManagedLocationIds = () => this.managedLocationIds;

  async loadManagedLocations() {
    const log = fileLog.getChildLogger(`loadManagedLocations`);

    const { total, data: locations } = await dispatch('addresses.runQuery', {
      query: {
        companies: {
          $in: _.compact([this.companyId, ...this.parentCompanyIds]),
        },

        subscriptions: {
          $elemMatch: {
            companyId: {
              $in: [this.companyId, ...this.parentCompanyIds],
            },
            isManager: true,
            userId: this.userId,
          },
        },
      },
    });
    log.debug(
      'Loaded %d Managed Locations for site manager %s',
      total,
      this.userId,
    );

    runInAction(() => {
      this.managedLocations.replace(locations);
    });

    return this.managedLocations;
  }

  @action.bound
  setSiteCount(count = 0) {
    this.siteCount = count;
  }

  @action.bound
  hasSavedLocations() {
    return !!this.siteCount;
  }

  // #endregion Company Management

  @computed
  get passwordUpdateDaysRemaining() {
    const passwordExpiresAt = _.get(
      this.user,
      'passwordExpiresAt',
      moment().add(90, 'days').toDate(),
    );
    return moment(passwordExpiresAt).diff(moment(), 'days');
  }

  getPasswordUpdateDaysRemaining() {
    return this.passwordUpdateDaysRemaining;
  }

  getUser() {
    return this.user;
  }

  isMe(user) {
    return _.get(user, 'uuid', user) === this.userId;
  }

  getCompany() {
    return this.company;
  }

  @computed
  get companyStatus(): CompanyStatusRecord | Record<string, never> | null {
    if (!_.get(this.company, 'uuid')) {
      return null;
    }
    const companyStatus =
      _.find(this.user.companyStatus, {
        company: this.company.uuid,
      }) || {};

    return companyStatus;
  }

  getCompanyStatus() {
    return this.companyStatus || {};
  }

  // #region Company Modules & Permissions

  @computed
  get modules() {
    return this.company?.modules || {};
  }

  @action.bound
  getModules() {
    return this.modules || {};
  }

  @action.bound
  isModuleEnabled(module) {
    return !!_.get(this.modules, module, false);
  }

  @computed
  get enabledModules() {
    return _(this.company.modules)
      .pickBy((val) => !!val)
      .keys()
      .value();
  }

  // #endregion Company Modules & Permissions

  @action
  updateUser(data = null) {
    const log = fileLog.getChildLogger(`updateUser`);
    log.debug('START', { user: data });

    if (_.isEmpty(data)) {
      this.user = {};
      log.debug('Logging Out User');
      app().emit('logout');

      // TODO: Clear out Sentry user Contexts
    } else {
      set(this.user, data);
      log.debug('Logging In User');
      app().emit('login', { user: this.user });
    }
    return this.user;
  }

  // #region Profile
  // TODO: Migrate to Profile store

  @action
  register({ email, password }) {
    const roles = ['user', 'employer'];
    const username = email;
    return service('user').create({ email, password, roles, username });
  }

  @action
  registerCustomer({ email, password }) {
    const roles = ['user', 'customer'];
    const username = email;
    return service('user').create({ email, password, roles, username });
  }

  @action
  updateProfile(userData) {
    return service('user')
      .patch(userData.uuid, userData)
      .then((user) => this.updateUser(user))
      .then(() => this.initAnalytics(this.user))
      .catch((err) => {
        fileLog.error('Error Updating User Profile', err);
        throw err;
      });
  }

  setAvatar({ userId = '', data }) {
    const log = fileLog.getChildLogger('setAvatar');
    return (
      _.isEmpty(userId)
        ? dispatch('auth.getLoggedInUser')
        : BBPromise.resolve({ uuid: userId })
    )
      .then((user) => {
        if (_.isEmpty(user)) {
          throw Error('No User Available');
        }

        return service('user').patch(user.uuid, { profileImage: data });
      })
      .then((res) => {
        log.debug('Uploaded Image! Response:', res);

        runInAction(() => {
          this.user.profileImageURL = res.profileImageURL;
        });

        return res;
      })
      .catch((err) => {
        fileLog.error('Failed Uploading Image', err);
      });
  }
  // #endregion Profile

  // #region SESSION

  // #region Impersonation & User/Company Setting

  /**
   * setCompany
   * @description Sets the currently "active" company for the logged in user.
   *
   * @param {UUID|Company|null} arg.company - The Company to impersonate. Null to clear
   * @param {UUID|User|null} arg.user - The User to act on
   * @param {boolean} arg.impersonate - Is the company different from the user's "primary" company
   *
   */
  @action.bound
  async setCompany({ company, user = this.user, impersonate = false }) {
    const log = fileLog.getChildLogger('setCompany');
    const prevCompanyId = this.company?.uuid;

    log.debug('START', { company, impersonate, user });

    if (_.isEmpty(company)) {
      if (!user.isAdmin) {
        log.error('NO COMPANY SELECTED FOR NON-AUTH USER', {
          user: _.get(user, 'uuid'),
        });
      }
      runInAction(() => {
        this.company = {};
      });
      await dispatch('prefs.setCompany', {
        company: this.company,
        impersonate,
        isAdmin: user.isAdmin,
        user,
      });
    } else {
      try {
        const co = _.isString(company)
          ? await dispatch('companies.get', company, {
              reload: true,
              select: false,
            })
          : { ...company };

        runInAction(() => {
          this.company = co;
        });

        // TODO: [SSM-493] this does not belong here, but the current AppNav
        // architecture forces us to set the siteCount synchronously here
        // instead of in an `onCompanyChange` event as we should be doing it.
        await dispatch('sites.runQuery', {
          query: {
            $limit: 0,
            companies: co.uuid,
          },
        }).then(({ total }) => {
          runInAction(() => {
            this.siteCount = total;
          });
        });

        if (
          !_.isEmpty(user.uuid) &&
          (!_.isEmpty(
            _.intersection(['employer', 'company-agent'], user.roles),
          ) ||
            (user.isAdmin && impersonate))
        ) {
          // TODO: might this be better as opt-in?
          if (global.TYPE === 'CLIENT' && !!this.company.uuid) {
            service('companies').patch(
              this.company.uuid,
              {},
              {
                query: {
                  $client: {
                    action: 'subscribeToChannel',
                    skipHooks: ['auditLog'],
                  },
                },
              },
            );
          }

          await dispatch('prefs.setCompany', {
            company: _.clone(this.company),
            impersonate,
            isAdmin: user.isAdmin,
            user,
          });

          if (this.isSiteManager && !this.isAdmin) {
            await this.loadManagedLocations();
          }
        }
      } catch (err) {
        log.error('Failed to set auth-company', err, {
          extra: { company, userId: _.get(user, 'uuid') },
        });
      }

      if (prevCompanyId !== this.company?.uuid && this.company?.uuid) {
        app().emit('login:company', {
          company: this.company,
          user: this.user,
        });
      }
    }

    const csr = _.find(user.companyStatus, {
      company: this.company.uuid,
    });

    if (
      !dispatch('routing.isAdminRoute') &&
      _.get(csr, 'status') === 'pending'
    ) {
      log.warn('User Account is "Pending" on the selected company', {
        company: company.uuid,
        csr: !global.IS_PRODUCTION && csr,
        user: user.uuid,
      });

      dispatch('routing.push', `/activate/${csr.company}`);
    }

    datadogRum.setUserProperty('activeCompany', this.company?.name);
    datadogRum.setUserProperty('activeCompanyId', this.company?.uuid);

    return this.company;
  }

  // #endregion Impersonation & User/Company Setting

  // #region Init

  /**
   * ## AuthStore.initCompanies
   * This method does two things: Calls `prefs.setup` which is responsible for setting the currently
   * active company, and loads the list of companies available to the logged in user.
   *
   * @param {*} user
   * @return {*}
   * @memberof AuthStore
   */
  async initCompanies(user) {
    const userId = _.get(user, 'uuid', user);
    const log = fileLog.getChildLogger(`initCompanies`);
    log.debug(`Loading Cos for User: ${userId}`);

    await dispatch('prefs.setup', user);

    log.debug('returning this.user: ', _.get(this.user, 'email'));
    return this.user;
  }

  async initChat(user, sessionType = global.TYPE) {
    const log = fileLog.getChildLogger('initChat');
    const userId = _.get(user, 'uuid', user);
    const csr = this.companyStatus || {};

    log.debug('Start: userId:', userId);
    if (
      sessionType === 'CLIENT' &&
      !!_.find(
        user.roles,
        (r) => /employer|company-agent/i.test(r) || user.isAdmin,
      ) &&
      // TODO: Remove once full enforcemnt of CASL is implemented w/in chat stores.
      (_.isEmpty(csr) || !_.includes(csr.roles, 'survey-review'))
    ) {
      // Don't await, allow conversations to get setup in the BG
      BBPromise.all(
        _.compact([
          dispatch('twilioConversations.setupMessaging', {
            company: this.company,
            user,
          }),
        ]),
      ).catch((err) => {
        dispatch('ui.snackBar.error', 'Unable to initialize chat');

        log.error('Unable to initialize Chat', err, {
          extra: { company: userId, csr },
        });
      });
    }
  }

  /* Setup Google Analytics */
  async initAnalytics(user) {
    const log = fileLog.getChildLogger('initAnalytics');
    // TODO allow admins to disable GA for specific users
    // Do not set 'employer' dimension if uuid is in configs gaExcludeUsers
    let excludeUsers = [];
    try {
      const response = await dispatch('configs.findOne', {
        query: {
          name: 'gaExcludeUsers',
        },
      });
      excludeUsers = response.configData;
    } catch (error) {
      log.warn('Error loading Google Analytics configData', error);
    }

    if (_.isEmpty(user?.uuid ?? user) || _.includes(excludeUsers, user.uuid)) {
      datadogRum.clearUser();
      datadogRum.stopSessionReplayRecording();
    } else {
      if (!_.includes(excludeUsers, user.uuid)) {
        datadogRum.startSessionReplayRecording();
        datadogRum.setUser({
          id: user.uuid,
          ..._.pick(user, ['roles', 'email']),
        });
      }
    }
  }

  // #endregion SESSION

  // #region Abilities Proxy
  can(...args) {
    return dispatch('abilities.can', ...args);
  }

  cannot(...args) {
    return dispatch('abilities.cannot', ...args);
  }
}
