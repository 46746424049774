import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

import BaseStore from './_baseStore';

export type IUserStatHistory = IBaseItem & Record<string, unknown>;

export default class UserStatHistoryStore extends BaseStore<IUserStatHistory> {
  constructor() {
    const baseItem = {
      adherence: null,
      attendance: null,
      company: null,
      date: null,
      hoursPerWeek: null,
      messageRead: null,
      onTime: null,
      openShiftPickup: null,
      rating: null,
      ratingCt: null,
      reliability: null,
      shiftCt: null,
      swapRequest: null,
      user: null,
      uuid: null,
    };
    super({
      baseItem,
      serviceName: 'userstatsHistories',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }
}
