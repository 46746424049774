import type { UIStoresType } from '#/shared/stores/ui';
import type { ICompany, ISite } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';

type SelectedZoneIds = UIStoresType['filters']['selectedZoneIds'];

export const getZoneIdsFromTiers = ({
  selectedZone,
  tierIndex,
  company, // dispatch('auth.getCompany')
  selectedZoneIds,
}: {
  company: ICompany;
  selectedZone?: ISite;
  selectedZoneIds: SelectedZoneIds;
  tierIndex?: number;
}): SelectedZoneIds => {
  const length = _.size(selectedZoneIds);

  const tierConfig = company?.settings?.zones?.tiers ?? [];

  // `selectedZoneIds` is empty, so initialized with null values
  if (!length) {
    return _.fill(Array(_.size(tierConfig)), null) as SelectedZoneIds;
  }

  /** When clearing a zone, we want to clear its children, as well
   * as any non-visible parents. This avoids "strange" behavior of
   * formatting when clearing out a selected zone and having that
   * zone's parents remain selected even though they are not visible.
   *
   * When selecting a zone should automatically make the parents zone selected,
   * but _only_ if those parent zones' tier is visible. This is the other
   * half of avoiding the "strange" behaviors described above
   */
  /** TODO: Determine what the interplay is with subscribed zones. EG,
   * if a user is managing a single parent zone that is not visible,
   * is this where we should "restore" it? _OR_ does the existing logic
   * which stores the user's `managedLocations` account for this properly?
   */
  return _.map(tierConfig, (tier, i) => {
    // Never set filters on invisible tiers
    if (!tier.isVisible) {
      return null;
    }
    // Clear out any filters on "child" zones
    if (i > tierIndex) {
      return null;
    }

    // If selecting a tier, return the UUID, otherwise clear it to null;
    if (i === tierIndex) {
      return selectedZone?.uuid ?? null;
    }

    // If selecting a zone, return it's relevant parentId,
    // If clearing, preserve the existing filter
    return selectedZone?.parentIds?.[i] ?? selectedZoneIds[i] ?? null;
  }) as SelectedZoneIds;
};
