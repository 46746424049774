import type {
  IIncident,
  IStatusFilterable,
} from '@shiftsmartinc/shiftsmart-types';

import { observable } from 'mobx';

import BaseStore from './_baseStore';

export default class IncidentStore
  extends BaseStore<IIncident>
  implements IStatusFilterable<IIncident>
{
  constructor() {
    super({
      baseItem: IncidentStore.BASE_ITEM,
      companyField: 'companyId',
      serviceName: 'incidents',
    });

    return this;
  }

  @observable
  categories = [
    {
      key: '1',
      resultCode: 410,
      text: 'Worker did not show up',
      value: 'worker:noShow',
    },
    {
      key: '2',
      text: 'Workers reported hours are incorrect',
      value: 'schedule:invalid',
    },
    {
      key: '3',
      resultCode: 405,
      text: 'Worker was unprofessional',
      value: 'worker:unprofessional',
    },
    {
      key: '4',
      resultCode: 502,
      text: 'Worker did not meet required criteria',
      value: 'worker:unqualified',
    },
    { key: '5', text: 'Other', value: 'worker:other' },
  ];

  @observable
  getCategories() {
    return this.categories;
  }

  static BASE_ITEM = {
    category: null,
    companyId: null,
    data: null,
    description: null,
    notes: [],
    reportType: null,
    status: null,
    statusLog: [],
    userId: null,
    workerId: null,
  };
}
