import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action } from 'mobx';

import { service } from '#/shared/app';

import BaseStore from './_baseStore';

export type IUserStat = IBaseItem & Record<string, unknown>;

export default class UserStatStore extends BaseStore<IUserStat> {
  constructor() {
    const baseItem = {
      adherence: null,
      attendance: null,
      company: null,
      hoursPerWeek: null,
      messageRead: null,
      onTime: null,
      openShiftPickup: null,
      rating: null,
      ratingCt: null,
      reliability: null,
      shiftCt: null,
      swapRequest: null,
      user: null,
      uuid: null,
    };
    super({
      baseItem,
      serviceName: 'userstats',
    });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  @action
  recalculate({ user, company }) {
    return service(this.serviceName).patch(
      null,
      {},
      {
        query: {
          $client: { recalculate: true },
          company,
          user,
        },
      },
    );
  }

  /**
   * recalculateForShift
   *
   * @param {*} { user, company, shift }
   * @returns
   * @memberof UserStatStore
   *
   * Only pass "user" to restrict to that specific partner
   */
  @action
  recalculateForShift({ user, company, shift }) {
    return service(this.serviceName).patch(
      null,
      {},
      {
        query: {
          $client: { recalculate: true },
          company,
          shift,
          user,
        },
      },
    );
  }
}
