import type { StoreName } from '#/shared/stores';

import _ from 'lodash';

/// alternative defaults should be
// draft: { color: 'var(--steel-75)' },
// active: { color: 'var(--ssm-blue)' },

export interface IStatusPropsItem {
  color?: string;
  text?: string;
}

export const statusMap = {
  assignmentsStatus: {
    checkedIn: { color: '#E3A75C' },
  },
  assignmentsStatusGroup: {
    active: { color: 'var(--violet)' },
    assigned: { color: 'var(--filled)' },
    cancelation: { color: 'var(--red)' },
    checkedIn: { color: 'var(--blue)' },
    completed: { color: 'var(--steel)' },
    noShow: { color: 'var(--orange-75)' },
  },
  campaignsStatus: {
    active: { color: '#0071D5' },
  },
  companiesStatus: {
    active: { color: 'var(--ssm-blue)' },
    deleted: { color: 'var(--red-75)' },
    demo: { color: 'var(--violet)' },
    draft: { color: '#975CE3' },
    pending: { color: '#975CE3' },
    unclaimed: { color: 'var(--orange-75)' },
  },
  defaultMap: {
    active: { color: '#E3A75C' },
    approved: { color: 'var(--green)' },
    archived: { color: 'var(--red-75)' },
    canceled: { color: '#E35C5C' },
    closed: { color: 'var(--red-75)' },
    completed: { color: '#32A623' },
    deleted: { color: '#E35C5C' },
    draft: { color: '#975CE3' },
    expired: { color: '#E35C5C' },
    filled: { color: 'var(--green)' },
    inProgress: { color: '#0071D5' },
    na: { color: 'var(--grey5)', text: 'N/A' },
    paid: { color: 'var(--green)' },
    pending: { color: '#975CE3' },
    rejected: { color: 'var(--red)' },
    unclaimed: { color: 'var(--orange-75)' },
  },
  dispatchPrefsStatus: {
    created: {
      color: 'var(--ssm-blue)',
      text: 'Queued',
    },
    empty: {
      color: 'var(--red)',
      text: 'No Matching Partners',
    },
  },
  incidentsStatus: {
    active: { color: 'var(--blue)' },
    pending: { color: 'var(--red)' },
    resolved: { color: 'var(--green)' },
  },
  isActive: {
    false: { color: 'var(--grey7)', text: 'Inactive' },
    null: { color: 'var(--grey5)', text: 'Inactive (null)' },
    true: { color: 'var(--blue)', text: 'Active' },
  },
  shopsStatus: {
    active: { color: '#0071D5' },
    pending: { color: 'var(--steel-75)' },
  },
  trainingsStatus: {
    active: { color: 'var(--green)' },
  },
};

export const getStatusMap = ({
  storeName = 'default',
  param = 'status',
  status,
}: {
  param: string;
  status: string;
  storeName?: StoreName | keyof typeof statusMap | 'default';
}): IStatusPropsItem =>
  _.get(
    statusMap,
    `${storeName}${_.upperFirst(param)}.${_.camelCase(status)}`,
    statusMap.defaultMap[_.camelCase(status)] ?? {
      color: '#975ce3',
    },
  );
