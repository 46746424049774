import type { ThingReassignConfirmModalRef } from '#/shared/components/dispatch/ThingReassignConfirmModal';
import type {
  IAssignment,
  ISite,
  IStatusGroupFilterable,
  StoreFilteringOptions,
  ICompany,
  IShift,
  IUser,
  IWorker,
  IThing,
  IAddress,
  IZone,
  ICampaign,
  ISurvey,
} from '@shiftsmartinc/shiftsmart-types';

import { action, computed, set, observable, runInAction } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'rfx-core';
import { Paginated, Params, Query } from '@feathersjs/feathers';

import {
  checkAssignmentPermission,
  checkThingPermission,
} from '#/shared/components/dispatch/ThingStatusIndicator.deprecated';
import BaseStore, {
  IStoreFindOpts,
  IStoreUpdateArgs,
  IStoreUpdateListOpts,
} from '#/shared/stores/_baseStore';
import { getChildLogger } from '#/shared/utils/client.logger';
import { app, service } from '#/shared/app';

// #region Types and Interfaces

export type AssignmentActionButtonHandler = {
  assignment?: IAssignment;
  /** @deprecated Use `shift` instead */
  selectedPTS?: IThing;
  shift?: IThing;
  thingReassignConfirmModal?: ThingReassignConfirmModalRef;
  user?: IUser;
  worker: IWorker;
};

export type AssignmentStoreCreateProps<T extends IThing | IShift | ISurvey> = {
  campaign?: ICampaign['uuid'];
  /** @deprecated */
  data?: Partial<IAssignment>;
  dataType?: T['category'];

  /** passes a param to do a "force" assignment over any conflicts */
  ignoreConflicts?: boolean;
  pay?: IThing['pay'];

  /** the returned assignment will have its `userObj` (and `user`) properties
   * populated with a partial user object. See the user resolvers in the API
   * for more details and advanced use cases
   */
  populateUser?: boolean | Array<keyof IUser> | Params;
  thing: T;
} & (
  | {
      worker: IWorker['uuid'];
    }
  | {
      /** @deprecated pass worker ID instead */
      worker?: IWorker;
    }
) &
  Partial<
    Pick<
      IAssignment<T>,
      | 'status'
      | 'requiresApproval'
      | 'dueDate'
      | 'approvalType'
      | 'start'
      | 'end'
    >
  >;

declare interface IConfirmationStatusFilterable {
  filter: {
    isConfirmed: boolean | null;
  };

  filterBy(
    {
      param,
      filter,
    }: {
      filter?: boolean | null;
      param: 'isConfirmed';
    },
    options?: IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
}

export type RosterGroupType = 'ref' | 'start_hour';

declare interface IGroupable {
  groupBy(string);
  groupingKey: RosterGroupType;
}
declare interface IScheduleTypeFilterable {
  filter: {
    shiftScheduleType: IAssignment<IShift>['data']['shiftScheduleType'] | null;
  };

  filterBy(
    {
      param,
      filter,
    }: {
      filter?: IAssignment<IShift>['data']['shiftScheduleType'] | null;
      param: 'shiftScheduleType';
    },
    options?: IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
}

declare type AssignmentStatusGroup =
  | 'assigned'
  | 'notConfirmed'
  | 'confirmed'
  | 'checkedIn'
  | 'active'
  | 'completed'
  | 'noShow'
  | 'cancelation';

type AssignmentsStoreFilter = IStatusGroupFilterable<
  IAssignment,
  AssignmentStatusGroup
>['filter'] & {
  /** Describes the selected address or addresses to be filtered. Overrides `filter.zones` */
  addressRef: IAddress['uuid'] | { $in: Array<IAddress['uuid']> | null };
  isLiveRosterView: boolean;
  /** Describes the selected zones to filter results by */
  parentAddressRef: IZone['uuid'];
  /** Describes the status, or statuses that are being filtered. Exclusive with statusGroup/IStatusGroupFilterable */
  status: string | string[];
} & IConfirmationStatusFilterable['filter'] &
  IScheduleTypeFilterable['filter'];

// #endregion Types and Interfaces
export default class AssignmentStore
  extends BaseStore<IAssignment, AssignmentsStoreFilter>
  implements
    IStatusGroupFilterable<IAssignment, AssignmentStatusGroup>,
    IScheduleTypeFilterable,
    IConfirmationStatusFilterable,
    IGroupable
{
  constructor() {
    super({
      baseFilter: AssignmentStore.BASE_FILTER,
      baseItem: AssignmentStore.BASE_ITEM,
      searchFields: ['uuid'],
      serviceName: 'assignments',
    });

    return this;
  }

  init() {
    super.init();

    app().on(
      'login:company',
      action(() => {
        // Reset the active filters
        set(this.filter, this.baseFilter);
      }),
    );
  }

  static BASE_ITEM: IAssignment = {
    // How many
    assignments: [{}],

    certs: null,

    checkIn: {},

    checkOut: {},

    clockTime: 0,

    company: null,

    data: {},

    dataType: '',

    // Date(endTime)
    duration: 0,

    // Date(startTime)
    end: null,

    // COmpany UUID
    path: null,

    payableDuration: 0,

    qaInfo: {},

    ref: '',

    requiresApproval: false,

    shiftNotes: '',

    shiftRating: null,

    // enum: [PENDING, COMPLETED, ETC]
    slots: null,

    // extends template
    // all from template
    start: null,

    // [ Forklift, etc ] (or UUID?)
    // ----
    status: null,

    // Assigned Workers
    swapStatus: null,

    swappedTo: null,

    timecard: [],

    workerNotes: '',
    workerRating: null,
  };

  static BASE_FILTER: AssignmentsStoreFilter = {
    addressRef: null,

    isConfirmed: null,

    shiftScheduleType: null,

    status: 'all',
    // @ts-expect-error missing the following properties from type 'IObservableArray<AssignmentStatusGroup | "all">' ts(2740)
    statusGroup: [],
    zones: null,
  };

  @observable
  surveyList = [];

  /**
   * ## groupingKey
   *
   * In roster list, we are grouping the roster (assignments) list by
   * shift, start hour
   */
  @observable
  groupingKey: RosterGroupType = 'ref';

  @observable
  assignmentsForWeek = [];

  @observable
  shiftModalList = [];

  @observable
  shiftModal = '';

  /*
    "total": "<total number of records>",
    "limit": "<max number of items per page>",
    "skip": "<number of skipped items (offset)>",
    "current": "<current page number>"
    "pages": "<total number of pages>"
  */
  @observable
  $surveyPagination = {};

  @observable
  $shiftModalPagination = {};

  @observable auxOptions = ['notConfirmed', 'confirmed'];

  @action
  setIsLiveRosterView(isLiveRosterView) {
    this.filter.isLiveRosterView = isLiveRosterView;
  }

  // TODO: Does this break any existing functionality by changing from 'all' to this

  defaultDateRangeForRosterList = {
    $gte: moment().startOf('day').toDate(),
    $lte: moment().endOf('day').toDate(),
  };

  @action.bound
  getStatusGroupFilterQuery(statusFilter: string[] = this.statusGroupFilter) {
    const query = {
      // We do not show any assignment of a cancelled shift, even with "Cancelled or No Show" option is selected
      'data.status': { $nin: ['Canceled', 'Deleted'] },
    };
    if (!_.includes(statusFilter, 'all')) {
      const statuses = [];

      if (_.includes(statusFilter, 'completed')) {
        statuses.push(...getStatusesForStatusGroup('completed'));
      }

      if (_.includes(statusFilter, 'cancelation')) {
        statuses.push('Canceled', 'Withdrawn');
        // What does expired on an assignment mean?
      }
      if (_.includes(statusFilter, 'noShow')) {
        statuses.push('NoShow');
      }

      if (_.includes(statusFilter, 'neverAccepted')) {
        statuses.push('Expired', 'Filled');
      }

      const checkinStatusQuery = {};
      if (_.includes(statusFilter, 'checkedIn')) {
        _.extend(checkinStatusQuery, {
          'checkIn.timestamp': { $exists: true },
          'checkOut.timestamp': { $exists: false },
          status: {
            $in: [
              ...getStatusesForStatusGroup('assigned'),
              ...getStatusesForStatusGroup('active'),
            ],
          },
        });
      }

      const assignedStatusQuery = {};
      if (_.includes(statusFilter, 'assigned')) {
        _.extend(assignedStatusQuery, {
          'checkIn.timestamp': { $exists: false },
          'checkOut.timestamp': { $exists: false },
          status: {
            $in: getStatusesForStatusGroup('assigned'),
          },
        });
      }

      const activeStatusQuery = {};
      if (_.includes(statusFilter, 'active')) {
        _.extend(activeStatusQuery, {
          'checkIn.timestamp': { $exists: true },
          'checkOut.timestamp': { $exists: false },
          status: {
            $in: getStatusesForStatusGroup('active'),
          },
        });
      }

      const statusQuery = _.compact([
        !_.isEmpty(checkinStatusQuery) && checkinStatusQuery,
        !_.isEmpty(assignedStatusQuery) && assignedStatusQuery,
        !_.isEmpty(activeStatusQuery) && activeStatusQuery,
        _.size(statuses) && { status: statuses },
      ]);

      if (!_.isEmpty(statusQuery)) {
        _.extend(query, {
          $or: statusQuery,
        });
        // Clear out status query if present
        query.status = undefined;
      }
    }
    return query;
  }

  @computed get partnerCancelReasons() {
    const company = dispatch('auth.getCompany');
    const companyPartnerCancelReasons =
      company?.settings?.shifts?.partnerCancelReasons || [];
    const companyPartnerCancelReasonsObjs = companyPartnerCancelReasons.map(
      (reason) => ({
        key: _.camelCase(reason),
        text: reason,
        value: _.camelCase(reason),
      }),
    );
    return _.uniqBy(
      _.concat(
        companyPartnerCancelReasonsObjs,
        {
          key: 'notInterested',
          text: 'I\x27m no longer interested in the role',
          value: 'notInterested',
        },
        {
          key: 'schedulingConflict',
          text: 'I have a scheduling conflict',
          value: 'schedulingConflict',
        },
        {
          key: 'inconvenientLocation',
          text: 'Shift location is inconvenient',
          value: 'inconvenientLocation',
        },
        {
          key: 'scheduledByMistake',
          text: 'Scheduled by mistake',
          value: 'scheduledByMistake',
        },
        {
          key: 'commutingIssue',
          text: 'I ran into an issue while commuting',
          value: 'commutingIssue',
        },
        {
          key: 'emergency',
          text: 'I had a personal emergency',
          value: 'emergency',
        },
        {
          key: 'sick',
          text: 'I do not feel well',
          value: 'sick',
        },
        {
          key: 'other',
          text: 'None of the above',
          value: 'other',
        },
      ),
      'value',
    );
  }

  /** NOTE
   * Normally, we don't want to override the initEvents method from the
   * base store, since it handles the standard `on*` events wrt the events
   * sent from the server. However, it is not easy to override arrow functions
   * from a parent class from a achild class, so we must rename the function
   * and _not_ call `super.initEvents`.
   *
   * However, in this case, the `onUpdated` events need to be overriden since
   * we can't be sure that the `user` property will be populated with a full
   * user object, or just their UUID (`populateUser` is not typically sent to
   * the `patch` method).
   *
   * There is no "Great" way to handle this since we want to be sure that the
   * user object remains popluated on the selected item or existing list items.
   *
   * Moving back to a smart user component, like we did with the UserObjCompoenent,
   * will eliminiate the need to do this since those components can intelligently
   * check if a user object or userID was passed, and lookup data as needed.
   */

  initEvents() {
    this.log.debug('assignments store initEvents()');
    service(this.serviceName).on('created', action(this.onCreated));
    service(this.serviceName).on('updated', action(this.onUpdatedLocal));
    service(this.serviceName).on('patched', action(this.onUpdatedLocal));
    service(this.serviceName).on('removed', action(this.onRemoved));
    service(this.serviceName).on(
      'updateAssignmentStatus',
      action(this.updateAssignmentsStatus),
    );
  }
  /**
   *
   * ## updateAssignmentsStatus
   * triggers when activating one or all draft shifts
   * it should update all updateAssignments statuses in shifts calendar view
   */
  updateAssignmentsStatus = (data: {
    assignmentIds: Array<IAssignment['uuid']>;
    status: IShift['status'];
  }) => {
    if (data && _.has(data, 'assignmentIds') && _.has(data, 'status')) {
      data.assignmentIds.map((assignmentId) => {
        const existing = _.find(this.list, { uuid: assignmentId });
        if (existing) {
          runInAction(() => {
            set(existing.data, 'status', data.status);
          });
        }
      });
    }
  };
  /**
   * ## approveTimeCard
   * Adds a new record to the timecard approvals array
   *
   * TODO: Move to bottom of file / into util methods
   */
  @action
  async approveTimeCard(assignment) {
    const userId = dispatch('auth.getUser')?.uuid;
    const companyId = dispatch('auth.getCompany')?.uuid;

    const data = {
      $push: {
        timecardApprovals: {
          approveDuration: assignment.duration,
          companyId,
          status: 'approved',
          time: moment().toDate(),
          userId,
        },
      },
      uuid: assignment.uuid,
    };

    return this.update({
      data,
      query: {
        timecardApprovals: {
          $not: {
            $elemMatch: {
              companyId,
              status: 'approved',
              userId,
            },
          },
        },
      },
    });
  }

  @action.bound
  async create<T extends IThing | IShift | ISurvey>({
    data = null, // @deprecated ?
    thing = null,
    worker,
    status,
    dataType,
    campaign,
    pay,
    requiresApproval,
    dueDate,
    approvalType,
    ignoreConflicts = false,
    populateUser = false,
    start = thing ? thing.start : undefined,
    end = thing ? thing.end : undefined,
  }: AssignmentStoreCreateProps<T>) {
    let newAssignment;
    const thingType = thing?.category || thing?.__t || dataType;

    if (data) {
      this.log.error(
        'Assignments store is using @deprecated "data" property to create assignment',
      );
      newAssignment = _.clone(data);
    } else {
      const user = _.get(worker, 'uuid', worker);

      let company = dispatch('auth.getCompany');
      const thingCoUUID = _.get(thing.company, 'uuid', thing.company);

      if (company.uuid !== thingCoUUID) {
        company = await dispatch('companies.get', thingCoUUID, {
          select: false,
        });
      }

      const newData = _.clone(thing);

      let assignedAt;

      if (thingType === 'survey') {
        const surveyDef = await dispatch(
          'surveyDefinitions.get',
          _.get(
            (thing as ISurvey).surveyDef,
            'uuid',
            (thing as ISurvey).surveyDef,
          ),
          {
            select: false,
          },
        );

        assignedAt = moment().toDate();

        _.extend(newData, { surveyDef });

        if (!_.isEmpty(thing.personaUUID)) {
          const persona = await dispatch('personas.get', thing.personaUUID);
          _.extend(newData, { persona });
        }
      }

      const { duration, payableDuration } = this.calcDurations({
        end,
        start,
        thing,
      });

      newAssignment = {
        approvalType,
        campaign,
        company: company.uuid,
        companyPath: company.path,
        data: newData,
        dataType: thingType,
        duration,
        end,

        payableDuration,
        positionId: thing.positionId,
        ref: thing.uuid,
        requiresApproval,
        roleId: thing.roleId,
        start,
        status,
        user,
      };

      if (_.isNumber(pay)) {
        newAssignment.data.pay = pay; // TODO: Check this logic [PDF 2019-04-02]
      }

      if (moment(dueDate).isValid()) {
        _.extend(newAssignment, { dueDate });
      }

      if (!_.isEmpty(campaign)) {
        _.extend(newAssignment, { campaign });
      }

      if (assignedAt) {
        _.extend(newAssignment, { assignedAt });
      }
    }

    const params = {
      query: {
        $client: {
          ignoreConflicts,
          populateUser,
        },
      },
    };

    this.log.debug('Creating New Assignment: ', newAssignment);

    return service(this.serviceName)
      .create(newAssignment, params)
      .then((res) => {
        this.log.debug('Created Assignment: ', res);
        return res;
      })
      .catch((err) => this.logAndThrow(err));
  }

  calcDurations({ start, end, thing }) {
    let { duration, payableDuration } = thing;
    duration = _.toFinite(duration);
    payableDuration = _.toFinite(payableDuration || duration);

    if (start !== thing.start || end !== thing.end) {
      duration = moment(end).diff(start, 'hours', true);

      // TODO: Proper calculation of payable duration
      payableDuration += duration - thing.duration;
      // this logic only increments/decrements the payable duration by the difference in durations
    }

    return { duration, payableDuration };
  }

  // Assigns worker with uuid: workerId to thing with uuid: thingId
  @action
  assign = async ({ thingId, workerId, bulk = false }) => {
    let res;
    try {
      if (!bulk) dispatch('ui.loadingModal.open', { message: 'Assigning' });
      const thing = await dispatch('things.get', thingId, { select: false });
      const worker = await dispatch('users.get', workerId, { select: false });
      const isInPast = moment(thing.end).isBefore(moment());
      // Assignment statuses cannot include "Draft", among possible other ones
      const statusToPersist = // ATTN: Assignment Status EP-5523
        isInPast && /shift/i.test(thing.__t) && /completed/i.test(thing.status)
          ? 'Completed'
          : 'Assigned';

      res = await this.create({
        populateUser: true,
        status: statusToPersist,
        thing,
        worker,
      });
    } catch (err) {
      if (!bulk) dispatch('ui.loadingModal.close');
      this.log.error(err);
      throw err;
    }
    if (!bulk) dispatch('ui.loadingModal.close');
    return res;
  };

  rate({
    assignment,
    worker,
    rating,
    note,
    id = _.get(assignment, 'uuid', assignment),
  }) {
    this.log.debug(
      'Updating Assignment %s with rating',
      id,
      rating,
      note,
      worker,
    );
    return this.update({
      data: {
        uuid: id,
        workerNotes: note,
        workerRating: rating,
      },
    });
  }

  find = async (query = {}, options?: IStoreFindOpts<IAssignment>) => {
    const opts = _.cloneDeep(options ?? {});
    opts.hooks = [
      ...(opts.hooks || []),
      () => {
        // $client can be undefined
        if (
          _.has(this.query?.query?.$client, 'searchByAssigneeName') &&
          _.isEmpty(this.query.query.$client.searchByAssigneeName)
        ) {
          // This is necessary to clear out any stale $client params.
          // The logic in `baseStore.find` only clears out query params
          // (when set as `[key]: undefined`), but that logic does not extend
          // to the $client params, which simply ignores the key passed
          // as `undefined` (default `_.extends` behavior)
          delete this.query.query.$client.searchByAssigneeName;
        }
      },
    ];

    return super.find(query, opts);
  };

  defaultStatuses = [
    // ATTN: Assignment Status EP-5523
    'Sent',
    'Assigned',
    'Accepted',
    'Approved',
    'Manual',
    'PendingApproval',
    'Completed',
  ];

  @computed
  get assignmentsByRef() {
    return _.groupBy(this.list, 'ref');
  }

  @computed
  get statusGroupFilter() {
    return this.filter.statusGroup;
  }

  @computed
  get statusFilter() {
    return this.filter.status;
  }

  @computed
  get assignmentsByUser() {
    return _.groupBy(this.list, 'user.uuid');
  }

  // ATTN: Assignment Status EP-5523
  getByWorker({ worker, start, end, statuses = this.defaultStatuses }) {
    const workerId = _.get(worker, 'uuid', worker);

    this.log.debug(`getByWorker for user "${workerId}"`);

    const startDate = start ? moment(start) : moment().subtract(7, 'd');

    const endDate = end ? moment(end) : moment().add(7, 'd');

    if (!workerId) {
      return Promise.reject('Please specify workerId');
    }

    return service(this.serviceName)
      .find({
        query: {
          end: { $gte: startDate.toDate() },
          start: { $lte: endDate.toDate() },
          status: { $in: statuses },
          user: workerId,
        },
      })
      .then(async (response) => {
        const assignments = response.data;

        response.data = await Promise.all(
          assignments.map((sa) => {
            if (_.isObject(sa.ref) && sa.ref.uuid) {
              return sa;
            }

            const assignment = sa;

            if (_.isEmpty(assignment.data)) {
              this.log.debug(
                'Assignment Does not have Embedded "Shift" info: looking Up...',
              );
              return service('shifts')
                .get(sa.ref)
                .then((shift) => {
                  assignment.data = shift;
                  return assignment;
                });
            }

            return assignment;
          }),
        );

        this.log.debug('Returning Assignments with Embedded "Shift" data: ', {
          assignments,
        });

        return response;
      })
      .then((response) => this.updateList(response));
  }

  @action.bound
  async getAggregateForWeek({ company, start: startVal, end: endVal }) {
    const start = moment(startVal).startOf('week');
    const end = endVal ? moment(endVal) : moment(startVal).endOf('week');

    const query = {
      company: _.get(company, 'uuid', company),
      dataType: 'shift',
      start: { $gte: start.toDate(), $lte: end.toDate() },
      status: {
        // ATTN: Assignment Status EP-5523
        $nin: [
          'Pending',
          'Oversubscribed',
          'PendingApproval',
          'Denied',
          'Declined',
          'Excluded',
          'Filled',
        ],
      },
    };

    const pipeline = [
      {
        $match: query,
      },
      {
        $group: {
          _id: {
            company: '$company',
            doy: { $dayOfYear: '$start' },
            user: '$user',
          },
          count: { $sum: 1 },
          shifts: {
            $push: {
              checkIn: '$checkIn.timestamp',
              checkOut: '$checkOut.timestamp',
              clockTime: '$clockTime',
              duration: '$duration',
              end: '$end',
              payableDuration: '$payableDuration',
              start: '$start',
              status: '$status',
              timecard: '$timecard',
              title: '$data.title',
              uuid: '$ref',
            },
          },
        },
      },
    ];

    try {
      const results = await this.runQuery({ _aggregate: pipeline });

      runInAction(() => {
        this.assignmentsForWeek = results;
      });
    } catch (err) {
      this.log.error('Failed to load assignments for week', err, { pipeline });
    }
  }

  /**
   * ## findByAddress
   *
   * @description Use to get the assignment list based on location of a company
   * grouped by the shifts and stor in the `assignmentsForLocation`
   *
   */
  @action.bound
  async findByAddress(
    {
      addressId,
      addressIds = _.compact([addressId]),
      isRemote,
    }: {
      addressId?: ISite['uuid'];
      addressIds?: Array<ISite['uuid']>;
      isRemote?: boolean;
    },
    opts?: { query?: Query } & IStoreFindOpts<IAssignment> &
      IStoreUpdateListOpts,
  ) {
    const addressQuery = isRemote
      ? {
          'data.addressRef': undefined,
          'data.isRemote': true,
        }
      : {
          'data.addressRef':
            _.isArray(addressIds) && _.size(addressIds)
              ? { $in: addressIds }
              : undefined,
          'data.isRemote': undefined,
        };

    this.filter.addressRef = _.isString(addressQuery['data.addressRef'])
      ? addressQuery['data.addressRef']
      : addressQuery['data.addressRef']?.$in || null;

    return this.find(
      {
        ...addressQuery,
        ...(opts?.query ?? {}),
      },
      _.omit(opts, 'query'),
    );
  }

  findByCompany = async ({
    company = dispatch('auth.getCompany'),
    query = {},
    opts,
  }) => {
    const authenticatedCompanyId = company.uuid;

    const companyQuery = {
      company: authenticatedCompanyId,
    };

    return this.find({ query: _.extend(companyQuery, query) }, opts);
  };

  @computed
  get assignmentsForWeekByUser() {
    return _.groupBy(this.assignmentsForWeek, 'id.user');
  }

  @action.bound
  async loadShiftModalAssignments({ query }) {
    try {
      const response = await this.runQuery(query);
      this.updateShiftModalList(response);
    } catch (error) {
      this.log.error('Error loading shifts to for shift modal: ', error);
    }
  }

  @action
  async reloadShiftModalAssignments({ company, shiftId }) {
    const query = {
      $client: { populateUser: true },
      // ATTN: Assignment Status EP-5523
      $select: [
        'uuid',
        'user',
        'start',
        'end',
        'ref',
        'data.uuid',
        'data.title',
        'data.address',
        'data.addressRef',
        'company',
        'workerRating',
        'workerNotes',
      ],

      company: _.get(company, 'uuid', company),

      dataType: 'shift',

      status: 'Completed',

      workerRating: { $exists: false },
    };
    _.extend(query, { ref: shiftId });
    await this.loadShiftModalAssignments({ query });
  }

  @action
  updateShiftModalList(json) {
    this.shiftModalList = _.get(json, 'data', json);
    if (json.data) {
      this.$shiftModalPagination = _.omit(json, 'data');
    }

    return this.shiftModalList;
  }

  @action
  setShiftModal(modal) {
    this.shiftModal = modal;
  }

  getSurveysByWorker({ worker, company = {} }) {
    const retail = _.get(company, 'modules.retail', false);
    if (!retail) {
      return false;
    }
    const companyId = _.get(company, 'uuid', company);
    const workerId = _.get(worker, 'uuid', worker);

    if (!workerId || !companyId) {
      return Promise.reject('Please specify workerId or companyId');
    }

    const surveyQuery = {
      'data.company': companyId,
      // ATTN: Assignment Status EP-5523
      dataType: 'survey',

      status: { $in: ['Assigned', 'Accepted'] },

      user: workerId,
    };

    return service(this.serviceName)
      .find({ query: surveyQuery })
      .then((response) => this.updateSurveyList(response));
  }

  @action
  updateSurveyList(json) {
    this.surveyList = _.get(json, 'data', json);
    if (json.data) {
      this.$surveyPagination = _.omit(json, 'data');
    }

    return this.surveyList;
  }

  get allStatuses() {
    return [
      'assigned',
      'checkedIn',
      'active',
      'completed',
      'noShow',
      'cancelation',
    ];
  }

  get defaultStatusGroupFilter(): Array<AssignmentStatusGroup> {
    const defaultGroups = _([
      'assigned',
      'notConfirmed',
      'confirmed',
      'checkedIn',
      'active',
      'completed',
    ])
      .intersection(this.allStatusGroups)
      .cloneDeep() as Array<AssignmentStatusGroup>;

    return defaultGroups;
  }

  get allStatusGroups() {
    let company: Partial<ICompany> = {};

    try {
      company = dispatch('auth.getCompany');
    } catch (err) {
      this.log.error("Failed to load auth'd company", err);
    }

    return _.compact([
      'assigned',
      'checkedIn',
      company.settings?.timekeeping?.enableBillableIntervals && 'active',
      'completed',
      'noShow',
      'cancelation',
    ]);
  }

  /* EVENTS */

  onCreated = (item) => this.addItem(item);

  /* Overwrites baseStore onUpdated method to
   * update local lists and populate user object
   */
  @action
  onUpdatedLocal = (data) => {
    if (_.isEmpty(data)) {
      this.log.debug(`Empty Item in onUpdated for ${this.serviceName}`);
      return false;
    }

    // ATTN: Assignment Status EP-5523
    if (!_.isEmpty(this.surveyList) && data.status === 'Canceled') {
      this.log.debug('Remove survey from users survey list');
      _.remove(this.surveyList, { uuid: data.uuid });
    }

    if (this.shiftModal) {
      if (/reviews/i.test(this.shiftModal) && !!data.workerRating) {
        _.remove(this.shiftModalList, { uuid: data.uuid });
        this.$shiftModalPagination.total -= 1;
      }

      const company = dispatch('auth.getCompany');
      const approvalsStatus = _.get(
        _.get(data, 'approvals', []).find((a) => a.company === company.uuid),
        'status',
      );
      if (
        /approvals/.test(this.shiftModal) &&
        (!/pendingapproval/i.test(data.status) ||
          /^(approved|denied)$/i.test(approvalsStatus))
      ) {
        _.remove(this.shiftModalList, { uuid: data.uuid });
        this.$shiftModalPagination.total -= 1;
      }
    }

    const existing = _.find(this.list, { uuid: data.uuid });

    if (existing && _.isObject(existing.user)) data.user = existing.user;

    return this.onUpdated(data);
  };

  /* ACTIONS */
  filterBy(
    value: {
      filter:
        | IAssignment['status']
        | 'all'
        | Array<IAssignment['status'] | 'all'>;
      param: 'status';
    },
    data?: StoreFilteringOptions & IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
  filterBy(
    value: {
      filter:
        | AssignmentStatusGroup
        | 'all'
        | 'default'
        | Array<AssignmentStatusGroup | 'all'>;
      param: 'statusGroup';
    },
    data?: StoreFilteringOptions & IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
  filterBy(
    value: {
      filter?: boolean | null;
      param: 'isConfirmed';
    },
    data?: IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
  filterBy(
    value: {
      filter?: boolean | null;
      param: 'isConfirmed';
    },
    data?: IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
  filterBy(
    value: {
      filter?: 'fixed' | 'flexible' | null;
      param: 'shiftScheduleType';
    },
    data?: IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
  filterBy(
    value: {
      filter?: IAddress['uuid'];
      param: 'addressRef';
    },
    data?: IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
  filterBy(
    value: {
      filter?: IZone['uuid'];
      param: 'parentAddressRef';
    },
    data?: IStoreFindOpts<IAssignment>,
  ): ReturnType<BaseStore<IAssignment>['find']>;
  @action.bound
  filterBy(value, data) {
    switch (value.param) {
      case 'statusGroup':
        return this.filterByStatusGroup(value.filter, data);
      case 'isConfirmed':
        return this.filterByIsConfirmed(value.filter, data);
      case 'shiftScheduleType':
        return this.filterByShiftScheduleType(value.filter, data);
      case 'addressRef':
      case 'parentAddressRef':
        return this.genericFilterBy(value, data);
      default:
        return this.legacyFilterBy(value, data);
    }
  }

  /**
   * ## groupedList
   *
   * Used to return the roster/assignment list according to the grouping key (by start hour / shift)
   * Right now there are only two types of grouping.
   * Checked if it is `ref` (ie: Grouped by Shift), then return the grouping by ref
   * Otherwise, do the grouping `start_hour` and return the list
   */
  @computed
  get groupedList() {
    return _(this.list)
      .orderBy(['start'], 'asc') // whether it is grouped by hour / shift, always show the oldest assignment on the top
      .groupBy(
        this.groupingKey === 'ref'
          ? 'ref'
          : ({ start }) => moment(start).format('YYYY-MM-DD-HH'),
      )
      .value();
  }

  @action.bound
  groupBy(groupingKey) {
    this.groupingKey = groupingKey;
  }

  /**
   * Toggles the state of the `isConfirmed` filter and queries the service.
   *
   * - If the current `filter.isConfirmed` is different from the input, the new value will be selected
   * - If the current `filter.isConfirmed` is the same as the input, the filter will be deactivated
   */
  @action.bound
  protected filterByIsConfirmed(
    isConfirmed: boolean | null,
    options?: IStoreFindOpts<IAssignment>,
  ): Promise<Array<IAssignment>> {
    this.filter.isConfirmed =
      this.filter.isConfirmed !== isConfirmed ? isConfirmed : null;
    return this.find(
      {
        partnerConfirmedAt: _.isBoolean(isConfirmed)
          ? { $exists: isConfirmed }
          : undefined,
      },
      { noQuery: () => !this.canQuery(), ...(options ?? {}) },
    );
  }

  @action
  async genericFilterBy({ param = null, filter = undefined } = {}, opts) {
    const query = {};
    let theFilter = filter;
    if (/^addressRef$/.test(param) && _.isString(filter)) {
      theFilter = this.filter[param] || [];
      if (_.includes(theFilter, filter)) {
        _.pull(theFilter, filter);
      } else {
        theFilter = [...theFilter, filter];
      }
    }
    this.filter[param] = theFilter;

    // Matches both `addressRef` and `parentAddressRef` based queries
    if (/addressRef/i.test(param)) {
      _.extend(query, this.getAddressQuery());
    }

    return this.find(query, {
      noQuery: () => !this.canQuery(),
      ...(opts ?? {}),
    });
  }

  @action.bound
  private getAddressQuery() {
    const query = {};

    if (this.filter.addressRef) {
      if (_.isArray(this.filter.addressRef)) {
        query['data.addressRef'] =
          _.size(this.filter.addressRef) > 1
            ? { $in: this.filter.addressRef }
            : _.first(this.filter.addressRef);
      } else {
        query['data.addressRef'] = this.filter.addressRef;
      }
      query['data.addressRef'] = this.filter.addressRef;
    } else if (this.filter.parentAddressRef) {
      query['data.parentAddressRefs'] = this.filter.parentAddressRef;
      query['data.addressRef'] = undefined;
    } else {
      query['data.addressRef'] = undefined;
      query['data.parentAddressRefs'] = undefined;
    }

    if (_.isArray(query['data.addressRef'])) {
      query['data.addressRef'] = { $in: query['data.addressRef'] };
    }

    return query;
  }

  canQuery(): boolean {
    return this.hasValidQuery;
  }

  @computed
  get hasValidQuery(): boolean {
    const {
      ref: selectedShiftId,
      'data.addressRef': addressId,
      'data.parentAddressRefs': zoneId,
      $client = {},
      'data.isRemote': isRemote,
      // 'data.shiftScheduleType': shiftScheduleType,
      status,
    } = this.query.query ?? {};
    const partnerSearch = _.get($client, 'searchByAssigneeName');
    const { statusGroup: statusGroupFilter, status: statusFilter } =
      this.filter;

    const addressIdCount = _.isString(addressId)
      ? 1
      : _.size(addressId?.$in) ?? 0;

    const canQuery =
      !!selectedShiftId ||
      ((_.size(statusGroupFilter) || _.size(statusFilter)) &&
        ((!_.isEmpty(addressId) && addressIdCount < 11) ||
          !_.isEmpty(zoneId) ||
          !_.isEmpty(partnerSearch) ||
          isRemote ||
          (_.size(statusGroupFilter) === 1 &&
            !_.includes(statusGroupFilter, 'all'))));

    this.log
      .getChildLogger('canQuery')
      .debug('Query %s proceed', canQuery ? 'can' : 'cannot', {
        addressId,
        isRemote,
        partnerSearch,
        selectedShiftId,
        statusGroupFilter,
      });

    return canQuery;
  }

  protected filterByStatusGroup(
    filter: AssignmentStatusGroup | 'all' | 'default',
    options?: StoreFilteringOptions & IStoreFindOpts<IAssignment>,
  ): ReturnType<AssignmentStore['find']>;
  @action.bound
  protected filterByStatusGroup(
    filter,
    { checked, exclusive, all, ...options },
  ) {
    // Unrelated, but needs to be added
    // const query = _.get(this.query, 'query', this.query);
    // query.start = query.start ?? this.defaultDateRangeForRosterList;

    let statusGroups = _.cloneDeep(this.filter.statusGroup ?? []) as Array<
      AssignmentStatusGroup | 'all' | 'default'
    >;
    if (exclusive) {
      statusGroups = [];
    }
    /**
     * During reset of the status filter, we pass `all` as true
     * As out put of reset, we only select the defaut status group
     */
    if (all || /default/i.test(filter)) {
      statusGroups = _.cloneDeep(this.defaultStatusGroupFilter);
    } else if (/all/i.test(filter)) {
      statusGroups = _.cloneDeep(this.allStatusGroups);
    } else if (checked) {
      statusGroups.push(filter);
    } else {
      if (statusGroups.includes('all')) {
        statusGroups = _.cloneDeep(this.defaultStatusGroupFilter);
      }
      _.pull(statusGroups, filter);
    }

    if (_(this.filter.statusGroup.slice()).xor(statusGroups).size()) {
      this.filter.statusGroup.replace(statusGroups);
      // this.filter.statusGroup = statusGroups;
    }

    const query = this.getStatusGroupFilterQuery(this.filter.statusGroup);

    return this.find(query, {
      noQuery: () => !this.canQuery(),
      ...(options ?? {}),
    });
  }

  @action.bound
  protected filterByShiftScheduleType(shiftScheduleType, options) {
    this.filter.shiftScheduleType =
      this.filter.shiftScheduleType !== shiftScheduleType
        ? shiftScheduleType
        : null;

    const query: Query = {
      'data.shiftScheduleType': shiftScheduleType ?? undefined,
    };

    return this.find(query, {
      noQuery: () => !this.canQuery(),
      ...(options ?? {}),
    });
  }

  @action
  protected legacyFilterBy(filter, { param = 'status', noQuery = false } = {}) {
    this.filter[param] = filter;

    const query: Query = {};

    if (param === 'status') {
      const statuses = getStatusesForStatusGroup(filter);

      if (_.isArray(statuses)) {
        query.status = { $in: statuses };
      } else {
        query.status = statuses;
      }
    }

    if (noQuery) {
      return Promise.resolve();
    }

    return this.find({ query });
  }

  /* Utils */
  /**
   * @deprecated Used on weekly calendar
   *  // ATTN: Assignment Status EP-5523
   */
  getDisplayPrefs({ assignment }) {
    const basic = /Sent|Pending|Declined|Excluded/i.test(assignment.status); // ATTN: Assignment Status EP-5523
    let color = 'blue';
    let icon;
    let labelClass;
    let content = assignment.status;

    if (/Declined/i.test(assignment.status)) {
      // ATTN: Assignment Status EP-5523 EP-5523
      color = 'red';
      icon = 'remove';
      content = false;
    } else if (/Accepted|Assigned|Approved/i.test(assignment.status)) {
      // ATTN: Assignment Status EP-5523 EP-5523
      color = 'green';
      icon = 'checkmark';
    } else if (/PendingApproval|Oversubscribed/i.test(assignment.status)) {
      // ATTN: Assignment Status EP-5523 EP-5523
      color = 'orange';
      icon = 'exclamation circle';
    } else if (/Pending/i.test(assignment.status)) {
      // ATTN: Assignment Status EP-5523 EP-5523
      color = 'grey';
    } else if (/Excluded/i.test(assignment.status)) {
      // ATTN: Assignment Status EP-5523 EP-5523
      color = 'grey';
      icon = 'remove';
      content = false;
    } else if (/Filled/i.test(assignment.status)) {
      // ATTN: Assignment Status EP-5523 EP-5523
      labelClass = 'dn';
    }

    if (/PendingApproval/i.test(assignment.status)) {
      // ATTN: Assignment Status EP-5523 EP-5523
      content = false;
    }

    return { basic, color, content, icon, labelClass };
  }
}

/** Related non-store methods */

const log = getChildLogger('assignments.statics');

function getDynamicStatus({ checkInAt, checkOutAt, status }) {
  log.silly('Checking Assignment Status: ', { checkInAt, checkOutAt, status });
  if (
    // ATTN: Assignment Status EP-5523
    /inProgress|active|assigned|accepted/i.test(status) &&
    !_.isEmpty(checkInAt) &&
    _.isEmpty(checkOutAt)
  ) {
    return 'checkedIn';
  }

  if (_.includes(getStatusesForStatusGroup('active'), status)) {
    return 'active';
  }
  return status;
}

/**
 * ### getStatusesForStatusGroup
 * For a given dynamic status group, returns which actual statuses should
 * be queried. For example, "assigned" maps to "Approved", "Accepted", and
 * of course "Assigned".
 *
 * **Exceptions**
 * * The `all` group will return undefined
 * * Specifying an unknown status group will return that string unchanged
 */
// function getStatusesForStatusGroup(group: 'all'): undefined;
function getStatusesForStatusGroup(
  group:
    | 'completed'
    | 'active'
    | 'assigned'
    | 'pending'
    | 'declined'
    | 'exception'
    | string,
): Array<IAssignment['status']> | string | undefined {
  switch (group) {
    case 'completed':
      return ['Completed'];
    case 'active':
      return ['EnRoute', 'Active', 'InProgress'];

    case 'assigned':
      return ['Approved', 'Accepted', 'Assigned'];

    case 'pending':
      return ['Pending', 'PendingApproval', 'Sent'];

    case 'declined':
      return ['Oversubscribed', 'Denied', 'Declined', 'Excluded', 'Filled'];
    case 'exception':
      return ['Expired', 'Canceled', 'NoShow', 'other'];

    case 'all':
      return undefined;
    default:
      return group;
  }
}

const assignWorker = ({
  user,
  worker,
  selectedPTS,
  shift = selectedPTS,
  assignment,
}: AssignmentActionButtonHandler) => {
  log.debug('Assigning worker shift: ', worker);

  return dispatch('ui.assignShiftModal.setup', {
    assignment,
    open: true,
    shift,
    thing: shift,
    user: user || worker || assignment?.user,
  });
};

const unassignWorker = ({
  user,
  worker,
  selectedPTS,
  shift = selectedPTS,
  assignment,
}: AssignmentActionButtonHandler) => {
  log.debug('Unassigning worker shift: ', worker);

  dispatch('ui.thingUnassignConfirmModal.setup', {
    assignment,

    // why do we user user here? What is difference between worker and user?
    open: true,
    thing: selectedPTS || shift || assignment?.data,
    worker: user,
  });
};

const reassignWorker = ({
  worker,
  selectedPTS,
  shift = selectedPTS,
  assignment,
  thingReassignConfirmModal,
}: AssignmentActionButtonHandler) => {
  log.debug('Unassigning worker shift: ', worker);

  thingReassignConfirmModal.current.setup({
    assignment,
    open: true,
    thing: selectedPTS || shift || assignment?.data,
    worker,
  });
};

const approveWorker = ({ worker, assignment }) => {
  log.debug('Approving worker shift', worker);
  const authUser = dispatch('auth.getUser');
  const company = dispatch('auth.getCompany');
  const query = {
    approvals: {
      $elemMatch: {
        company: company.uuid,
      },
    },
  };
  const data = {
    'approvals.$.approvedAt': moment().toDate(),
    'approvals.$.status': 'approved',
    'approvals.$.updatedBy': authUser.uuid,
    uuid: assignment?.uuid,
  };
  return dispatch('assignments.update', {
    data,
    query,
  });
};

const openDetailApprovalModal = ({ assignment, shift }) => {
  dispatch('assignments.setSelected', assignment);
  dispatch(
    'ui.shiftApprovalsModal.setupSimple',
    _.get(assignment, 'user.uuid', assignment.user),
    {
      assignment,
      shift,
    },
  );
};

const openRemindersModal = ({ assignment }) => {
  dispatch('ui.shiftRemindersModal.setup', { assignment });
};

const confirmPartner = ({ assignment }) => {
  const authUserId = dispatch('auth.getUser').uuid;
  dispatch('assignments.update', {
    data: {
      confirmSource: 'employerPortal',
      confirmedBy: authUserId,
      partnerConfirmedAt: moment().toDate(),
      uuid: assignment.uuid,
    },
  });
};

const denyWorker = ({ worker, assignment }) => {
  log.debug('Approving worker shift', worker);

  const authUser = dispatch('auth.getUser');
  const company = dispatch('auth.getCompany');
  const query = {
    approvals: {
      $elemMatch: {
        company: company.uuid,
      },
    },
  };
  const data = {
    'approvals.$.deniedAt': moment().toDate(),
    'approvals.$.status': 'denied',
    'approvals.$.updatedBy': authUser.uuid,
    uuid: assignment?.uuid,
  };
  return dispatch('assignments.update', {
    data,
    query,
  });
};

const markNoShow = ({ worker, assignment }) => {
  log.debug('No Show worker shift', worker);

  const data = {
    status: 'NoShow', // ATTN: Assignment Status EP-5523
    uuid: _.get(assignment, 'uuid', assignment),
  };
  dispatch('assignments.update', { data });
};

const markCheckIn = ({ worker, assignment }) => {
  log.debug('mark check in', worker);
  const user = dispatch('auth.getUser').uuid;

  const data = {
    checkIn: {
      timestamp: moment(),
      user,
    },
    uuid: _.get(assignment, 'uuid', assignment),
  };
  dispatch('assignments.update', { data });
};

const markCheckOut = ({ worker, assignment }) => {
  log.debug('mark check out', worker);
  const user = dispatch('auth.getUser').uuid;

  const data = {
    checkOut: {
      timestamp: moment(),
      user,
    },
    uuid: _.get(assignment, 'uuid', assignment),
  };
  dispatch('assignments.update', { data });
};

const openTimecardEdit = ({ assignment }) => {
  dispatch('ui.timecardModal.setup', { assignment });
};

const openCheckInCheckOut = ({ worker, assignment }) => {
  dispatch('ui.checkInCheckOutModal.setup', { assignment, worker });
};

// CP from AssignShiftModal.cancelAssignment
const cancelInvite = async ({ assignment }) => {
  const authUser = dispatch('auth.getUser');

  try {
    if (assignment.isStub) {
      const { ref, inviteId } = assignment;

      const exception = {
        refId: ref,
        status: 'canceled',
        timestamp: new Date(),
        userId: authUser?.uuid,
      };

      if (inviteId) {
        const updatedInvite = await dispatch('invites.update', {
          data: {
            $push: { exceptions: exception },
          },
          id: inviteId,
        });

        log.debug('Updated User Invite: ', { updatedInvite });

        return updatedInvite;
      }
    } else {
      const id = _.get(assignment, 'uuid', assignment);

      const data = {
        // ATTN: Assignment Status EP-5523
        canceledAt: moment().toDate(),
        canceledBy: authUser.uuid,
        status: 'Canceled',
        updatedNote: `Canceled by Employer: ${authUser.displayName}`,
      };

      return dispatch('assignments.update', { data, id });
    }
  } catch (err) {
    log.error('failed to update invite', err, { assignment });
  }

  dispatch('ui.snackBar.error', 'Unable to cancel assignment');
  return null;
};

/** getAssignmentActions
 *
 * @param assignment
 * @returns array of action button text and handlers
 */
function getAssignmentActions(assignment: IAssignment) {
  const company = dispatch('auth.getCompany');
  const enableManualShiftReminders = _.get(
    company,
    'settings.things.enableManualShiftReminders',
    false,
  );
  const enableCheckInCheckOut = _.get(
    company,
    'settings.things.enableCheckInCheckOut',
    false,
  );

  const disableTimekeepingEdit = _.get(
    company,
    'settings.timekeeping.employerEditDisabled',
    false,
  );

  const enableShiftSwap = _.get(
    company,
    'settings.things.enableShiftSwaps',
    false,
  );

  const { start, data: shift } = assignment ?? {};

  const isShiftCanceled = /^canceled$/i.test(shift?.status);

  // If shift is canceled, don't show action buttons
  if (isShiftCanceled) {
    return [];
  }

  const firstName = _.get(assignment, 'user.firstName', 'Partner');

  const timeToStart = moment(start).diff(moment(), 'minutes');

  const allowStartingShiftActions = enableCheckInCheckOut && timeToStart <= 120; // New check is 2 hours
  const lateNoShow = timeToStart <= -15;

  const reminders = _.get(assignment, 'reminders', [])
    .filter((r) => r.reminderType === 'manualRemindShift')
    .sort((r1, r2) => (moment(r1.remindedAt).isAfter(r2.remindedAt) ? -1 : 1));
  const latestManualReminder = _.head(reminders);
  const confirmShiftTime = _.get(
    company,
    'settings.things.customConfirmShiftTime',
    1440,
  );
  const showConfirmPartner =
    timeToStart < confirmShiftTime && moment().isBefore(moment(start));

  const hideRemindButton =
    (!_.isEmpty(latestManualReminder) &&
      moment().diff(moment(latestManualReminder.remindedAt), 'hours') < 8) ||
    !!assignment.partnerConfirmedAt;

  const actionButtonObjects = {
    approve: {
      action: openDetailApprovalModal,
      text: 'Approve',
      tooltip: `${firstName} will be approved and assigned to this shift and notified.`,
    },
    assign: {
      action: assignWorker,
      text: 'Assign',
      tooltip: `${firstName} will be assigned to this shift and notified.`,
    },
    cancelInvite: {
      action: cancelInvite,
      text: 'Cancel',
      tooltip: `${firstName} will be un-invited from this shift.`,
    },
    checkIn: {
      action: markCheckIn,
      text: 'Check-In',
      tooltip: `${firstName} will be checked-in with the current time.`,
    },
    checkOut: {
      action: markCheckOut,
      text: 'Check-Out',
      tooltip: `${firstName} will be checked-out with the current time.`,
    },
    confirmPartner: {
      action: confirmPartner,
      text: 'Confirm',
      tooltip: `Click here if ${firstName} has contacted you directly (outside of Shiftsmart) to confirm they are working this shift.`,
    },
    editCheckInOut: {
      action: openCheckInCheckOut,
      text: 'Edit Check-In/Out Times',
      tooltip: `Manually update attendance times for ${firstName}.`,
    },
    editTimekeeping: {
      action: openTimecardEdit,
      text: 'View Timecard',
      tooltip: `Manually update attendance times for ${firstName}.`,
    },
    markNoShow: {
      action: markNoShow,
      text: 'Mark No Show',
      tooltip: `${firstName} will be marked as no-show.`,
    },
    reassign: {
      action: reassignWorker,
      text: 'Reassign',
      tooltip: `${firstName} will be reassigned from this shift.`,
    },
    remind: {
      action: openRemindersModal,
      text: 'Remind',
      tooltip: `${firstName} will be sent an SMS reminder with the option to confirm this shift.`,
    },
    unassign: {
      action: unassignWorker,
      text: 'Unassign',
      tooltip: `${firstName} will be unassigned from this shift and notified.`,
    },
  };

  const actionButtons = [];

  // ATTN: Assignment Status EP-5522
  switch (_.toLower(assignment.status)) {
    case 'pendingapproval':
      actionButtons.push(actionButtonObjects.approve);
      break;
    case 'canceled':
    case 'pending':
      actionButtons.push(actionButtonObjects.assign);
      break;
    case 'noshow':
      actionButtons.push(actionButtonObjects.editTimekeeping);
      actionButtons.push(actionButtonObjects.unassign);
      break;
    case 'completed':
      actionButtons.push(actionButtonObjects.editTimekeeping);
      actionButtons.push(actionButtonObjects.unassign);
      break;
    case 'invited':
    case 'sent':
      if (enableManualShiftReminders && !hideRemindButton) {
        actionButtons.push(
          _.extend({}, actionButtonObjects.remind, {
            tooltip: `${firstName} will be sent an SMS reminder with the option to accept this shift.`,
          }),
        );
      }
      actionButtons.push(actionButtonObjects.assign);
      actionButtons.push(actionButtonObjects.cancelInvite);
      break;
    case 'approved':
    case 'accepted':
    case 'assigned':
      if (allowStartingShiftActions) {
        if (_.isEmpty(assignment.checkIn)) {
          if (lateNoShow) {
            actionButtons.push(actionButtonObjects.editTimekeeping);
          } else if (!disableTimekeepingEdit) {
            actionButtons.push(actionButtonObjects.checkIn);
            actionButtons.push(actionButtonObjects.markNoShow);
          }
        } else if (_.isEmpty(assignment.checkOut)) {
          if (!disableTimekeepingEdit) {
            actionButtons.push(actionButtonObjects.checkOut);
          }
          actionButtons.push(actionButtonObjects.editTimekeeping);
        } else {
          // Show Edit Check-In/Out if checked in
          actionButtons.push(actionButtonObjects.editTimekeeping);
        }
      } else if (enableManualShiftReminders && !hideRemindButton) {
        actionButtons.push(actionButtonObjects.remind);
      }
      if (showConfirmPartner) {
        actionButtons.push(actionButtonObjects.confirmPartner);
      }
      if (
        (!_.isEmpty(assignment.checkIn) || !_.isEmpty(assignment.checkOut)) &&
        !_.includes(
          actionButtons.map((aButton) => aButton.text),
          'View Timecard',
        )
      ) {
        // Show Timekeeping if checked out / in, if not shown already
        actionButtons.push(actionButtonObjects.editTimekeeping);
      }
      actionButtons.push(actionButtonObjects.unassign);
      if (enableShiftSwap) {
        actionButtons.push(actionButtonObjects.reassign);
      }
      break;
    case 'declined':
    default:
      break;
  }
  return actionButtons;
}

/** getWorkerCardAction
 * @deprecated [Rohan 10/8/19]
 */
const getWorkerCardAction = ({
  worker,
  selectedPTS,
  shift = selectedPTS,
  assignment,
  isCompact,
  iconOnly,
}) => {
  // const assignmentStatus = _.get(assignment, 'status', '').toLowerCase();
  const style = { whiteSpace: 'nowrap' };
  if (!isCompact) {
    style.width = '8rem';
  }

  const retButton = getBaseButton({
    assignment,
    iconOnly,
    isCompact,
    selectedPTS,
    shift,
    worker,
  });

  const requiresApproval = _.get(assignment, 'requiresApproval');

  if (_.get(assignment, 'requiresApproval')) {
    const retval = [
      {
        basic: true,
        className: 'bare',
        color: requiresApproval ? 'red' : 'blue',
        icon: requiresApproval ? 'lock' : 'unlock',
        onClick: () => _.noop(),
        popup: 'This partner requires manager approval before being assigned',
        size: 'mini',
        style: { cursor: 'default' },
      },
      retButton,
    ];

    if (/pendingApproval/i.test(assignment.status)) {
      retval.push({
        basic: true,
        color: 'red',
        icon: 'ban',
        onClick: denyWorker,
        popup: 'Deny this request',
        size: 'mini',
      });
    }

    return retval;
  }

  return retButton;
};

/** getBaseButton
 * @deprecated [Rohan 10/8/19]
 */
function getBaseButton({
  worker,
  selectedPTS,
  shift = selectedPTS,
  assignment,
  isCompact,
  iconOnly = false,
}) {
  const style = { whiteSpace: 'nowrap' };
  if (!isCompact) {
    style.width = '8rem';
  }
  const assignmentStatus = _.get(assignment, 'status', '').toLowerCase();
  const baseButton = {
    basic: false,
    content: iconOnly ? undefined : _.capitalize(assignmentStatus),
    labelPosition: 'left',
    size: 'mini',
    style,
  };

  if (!assignment) {
    return _.extend(baseButton, {
      color: 'teal',
      content: iconOnly ? undefined : 'Assign',
      icon: 'plus',
      onClick: assignWorker,
    });
  }

  switch (assignmentStatus) {
    case 'canceled':
      return _.extend(baseButton, {
        basic: true,
        color: 'teal',
        content: iconOnly ? undefined : 'Assign',
        icon: 'remove',
        onClick: getAssignmentActions(assignment),
      });
    case 'declined':
      return _.extend(baseButton, {
        basic: true,
        color: 'red',
        content: iconOnly ? undefined : 'Declined',
        icon: 'ban',
      });
    case 'pendingapproval':
      return _.extend(baseButton, {
        basic: false,
        color: 'blue',
        content: iconOnly ? undefined : 'Approve',
        icon: 'question',
        onClick: getAssignmentActions(assignment),
      });
    case 'pending':
    case 'sent':
      return _.extend(baseButton, {
        basic: true,
        color: 'blue',
        icon: 'send',
        onClick: getAssignmentActions(assignment),
      });
    case 'approved':
    case 'accepted':
    case 'assigned':
      return _.extend(baseButton, {
        basic: false,
        color: 'blue',
        icon: 'check',
        onClick: getAssignmentActions(assignment),
      });
    default:
      break;
  }

  if (
    /^(enroute|active|inprogress|completed|expired|noshow)$/.test(
      assignmentStatus,
    )
  ) {
    let content = _.capitalize(assignmentStatus);
    let icon = 'circle outline';

    switch (assignmentStatus) {
      case 'enroute':
        content = 'En Route';
        icon = 'arrow right';
        break;
      case 'inprogress':
        content = 'In Progress';
        icon = 'play';
        break;
      case 'active':
        icon = 'play';
        break;
      case 'completed':
        icon = 'check';
        break;
      case 'expired':
        icon = 'clock';
        break;
      case 'noshow':
        content = 'No Show';
        icon = 'exclamation';
        break;
      default:
        break;
    }
    return _.extend(baseButton, {
      // ATTN: Assignment Status EP-5523
      basic: !/completed|noshow/i.test(assignmentStatus),

      // ATTN: Assignment Status EP-5523
      content,

      icon,
      // ATTN: Assignment Status EP-5523
      negative: /expired|noshow/i.test(assignment.status),
      positive: !/expired|noshow/i.test(assignment.status),
    });
  }

  if (
    checkThingPermission(shift, 'canceled') &&
    checkAssignmentPermission(assignment, 'canceled')
  ) {
    return {
      basic: true,
      content: assignment.status,
      negative: true,
      onClick: ({ user }) => {
        dispatch('ui.thingUnassignConfirmModal.setup', {
          assignment,
          open: true,
          thing: shift || assignment.data,
          worker: user || worker,
        });
      },
      size: 'mini',
    };
  }

  return _.extend(baseButton, {
    color: 'teal',
    icon: 'question',
  });
}

export {
  getDynamicStatus,
  getStatusesForStatusGroup,
  getWorkerCardAction,
  getAssignmentActions,
  assignWorker,
  unassignWorker,
  approveWorker,
};
