import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import _ from 'lodash';
import { action } from 'mobx';

import BaseStore from './_baseStore';

const userSummary = {
  displayName: '',
  profileImageURL: '',
  user: '',
};

export type ISwap = IBaseItem & Record<string, unknown>;

export default class SwapStore extends BaseStore<ISwap> {
  constructor() {
    const baseItem = {
      company: '',
      end: '',
      recipient: userSummary,
      selectedUsers: [],
      sender: userSummary,
      start: '',
      status: '',
      thing: '',
      title: '',
      userRequests: [],
    };
    super({ baseItem, serviceName: 'shiftswaps' });

    this.selected = _.clone(this.baseItem);

    return this;
  }

  @action
  deny({ swap }) {
    return this.update({
      data: {
        status: 'denied',
      },
      id: swap.uuid,
    });
  }

  @action
  approve({ swap }) {
    return this.update({
      data: {
        status: 'approved',
      },
      id: swap.uuid,
    });
  }

  @action
  assign({ swap, worker }) {
    return this.update({
      data: {
        action: 'assign',
        recipient: {
          displayName: worker.displayName,
          profileImageURL: worker.profileImageURL,
          userId: worker.uuid,
        },
      },
      id: swap.uuid,
    });
  }
}
