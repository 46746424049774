import { browserHistory } from 'react-router';
import _ from 'lodash';
import { dispatch } from 'rfx-core';
import isUUID from 'uuid-validate';
import qs from 'query-string';

import { parseQueryString } from '#/shared/utils/parseQueryString';

if (global.TYPE === 'CLIENT') {
  browserHistory.listen((location) => {
    const authUser = dispatch('auth.getUser');
    if (authUser?.uuid) {
      dispatch('audit.create', {
        data: {
          action: 'Routing',
          location,
        },
      });
    }
  });
}

declare interface GotoRoute {
  route: string;
  state?: never;
  uuid?: never;
}
declare interface GotoState {
  route?: never;
  state: 'review' | 'contentLink' | 'home' | string;
  uuid?: string;
}

/**
 * Routing Store
 *
 * A Domain-Specific layer built on top of React-Router v3's router. By using these
 * methods, we can more easily swap out our (ancient) routing infrastructure for something
 * more modern and only have to make linking changes here, rather than throughout the
 * codebase.
 *
 * **Caveat** This does not handle the use of the RR `Link` component
 *
 * @export
 * @class Routing
 */
export default class Routing {
  // @observable.deep
  // urlLocation;

  // constructor() {
  //   if (global.TYPE === 'CLIENT') {
  //     this.urlLocation = browserHistory.getCurrentLocation();
  //     browserHistory.listen(
  //       action(location => {
  //         this.urlLocation = location;
  //       }),
  //     );
  //   }
  //   return this;
  // }

  async goto({ state = null, route = null, uuid }: GotoState | GotoRoute) {
    if (!browserHistory) {
      return null;
    }
    if (!_.isEmpty(route)) {
      return browserHistory.push(route);
    }

    let refObj;

    if (!_.isEmpty(state)) {
      switch (state) {
        case 'swaps':
          refObj = await dispatch('swaps.get', uuid);

          if (refObj) {
            return this.push(`/shifts/${refObj.thing}?activeTab=swaps`);
          }
          break;
        case 'review':
        case 'reviews':
        case 'shifts':
          return browserHistory.push(_.compact(['/shifts', uuid]).join('/'));
        case 'contentLink':
          return this.gotoContentLink(route);
        case 'home':
          return browserHistory.push(dispatch('prefs.getHomeRoute') || '/');
        default:
          return browserHistory.push(
            _.compact(['/shifts', uuid]).join('/').replace('//', '/'),
          );
      }
    }

    return null;
  }

  /**
   * ## home
   * Routes the user to their specified "Home" route. The Home route for each
   * user is determined by the `getHomeRoute` method in the prefs store and
   * depends on the user type and agent preferences.
   *
   * @memberof Routing
   */
  home() {
    return this.goto({ state: 'home' });
  }

  /**
   * ## push
   * Routes the user to the path specified in the `route` parameter, appending the
   * supplied query params (url encoded) to the URL if specified.
   *
   * Routes are generally specified with a leading slash to peg the route to the site's
   * base URL. Omitting the leading slash will push the specified route onto the current
   * path.
   *
   * @see browserHistory.push
   *
   * @example
   * localhost/shifts   push('/users/someUserId') --> localhost/users/someUserId
   * localhost/shifts   push(shift.uuid) --> localhost/shifts/{shift.uuid}
   * localhost/shifts   push('/auth', { redirect: '/shifts' }) --> localhost/auth?redirect=%2fshifts
   * push('/partners/uuid', {}, { openNewTab: true }) --> localhost/partners/uuid in new tab
   *
   * @param {*} route
   * @param {*} queryParams
   * @memberof Routing
   */
  async push(
    route: string,
    queryParams?: Record<string, unknown>,
    opts?: {
      openNewTab?: boolean;
    },
  ) {
    route = queryParams ? `${route}?${qs.stringify(queryParams)}` : route;

    if (opts?.openNewTab) {
      return window.open(route, '_blank');
    }

    return this.goto({
      route,
    });
  }

  /**
   * ## replace
   * Does an in-place replacement of the current URL without reloading the page. This is
   * especially useful if adding query parmeters representing the current state of the
   * page (ie: selected tab).
   *
   * @see browserHistory.replace
   *
   * @param {*} route
   * @param {*} queryParams
   * @memberof Routing
   */
  replace(route: string, queryParams?: Record<string, unknown>) {
    if (!browserHistory) {
      return null;
    }
    return browserHistory.replace(
      queryParams
        ? `${route}?${qs.stringify(
            _.omitBy(queryParams, (paramValue) => _.isUndefined(paramValue)),
          )}`
        : route,
    );
  }

  /**
   * ## addQuery
   * Primarily used as a wrapper over the {@link Routing.replace} method to add or change
   * query parameters to the URL. The second argument is used to override the default behavior
   * and call {@link Routing.push} instead.
   *
   * @param {*} queryParams
   * @param {boolean} [push=false]
   * @memberof Routing
   */
  addQuery(queryParams: Record<string, unknown>, push = false) {
    if (global.IS_SSR) return;
    const existingQueryParams = parseQueryString();
    if (push) {
      this.push(
        window.location.pathname,
        _.extend({}, existingQueryParams, queryParams),
      );
    } else {
      this.replace(
        window.location.pathname,
        _.extend({}, existingQueryParams, queryParams),
      );
    }
  }

  /**
   * ## pop
   * Pops the last segment of the URL path and redirects to its parent.
   * Useful for routing from a details page back to its list page.
   *
   * ### Usage
   *
   * @param {number} [ct=1]
   * Specifies how many path segments to pop off of the url
   *
   * @memberof Routing
   *
   * @example
   * localhost/shifts/some-uuid-for-a-shift: pop() --> localhost/shifts
   */
  pop(ct?: number): void {
    if (global.IS_SSR) return;
    const path = window.location.pathname.split('/');
    if (path.length <= 2) return;
    path.splice(-(ct ?? 1));
    this.push(path.join('/'));
  }

  /**
   * ## refresh
   * Reloads the current page
   *
   * @param {*} { path: inputPath, reload = false, setMainPath = false }
   * @memberof Routing
   */
  async refresh({
    path: inputPath,
    reload = false,
    setMainPath = false,
  }: {
    path?: string;
    reload?: boolean;
    setMainPath?: boolean;
  }): Promise<void> {
    if (global.IS_SSR) return;
    let path = inputPath || window.location.pathname;
    if (setMainPath) {
      path = _(path.split('/'))
        .reject((p) => isUUID(p))
        .join('/')
        .replace('//', '/');
    }
    await this.push(path);

    if (!!reload && !global.IS_SSR) {
      window.location.reload(true);
    }
  }

  /**
   * ## gotoContentLink
   * Parses the link and routes the user to the expected page.
   *
   * @todo TODO: Combine with the goto method above
   *
   * @param {*} contentLink
   * @memberof Routing
   */
  async gotoContentLink(contentLink: string) {
    const [store, uuid] = _.compact(contentLink.split('/'));

    let refObj;

    /* eslint-disable no-fallthrough */
    switch (_.toLower(store)) {
      case 'swaps':
        refObj = await dispatch('swaps.get', uuid);

        if (refObj) {
          return this.push(`/shifts/${refObj.thing}?activeTab=swaps`);
        }
      case 'locationgroups':
        return dispatch('ui.snackBar.open', 'Unknown Content Link');
      case 'shifts':
      case 'reviews':
        return this.push(`/shifts/${uuid}`);
      default:
        return this.push(contentLink);
    }
    /* eslint-enable no-fallthrough */
  }

  /**
   * ## isAdminRoute
   * Returns whether or not the current path is within the admin portal
   *  {boolean}
   * @memberof Routing
   */
  isAdminRoute(): boolean {
    const path = dispatch('app.getPathname');
    return /\/admin($|\/)/.test(path);
  }

  isRouteMatch(regex: Regexp) {
    return !!regex && regex.test(window.location.pathname);
  }
}
