import type { IBaseItem } from '@shiftsmartinc/shiftsmart-types';

import BaseStore from './_baseStore';

export type IReports = IBaseItem & Record<string, unknown>;

export default class ReportsStore extends BaseStore<IReports> {
  constructor() {
    super({
      baseItem: ReportsStore.BASE_ITEM,
      companyField: 'company',
      serviceName: 'reports',
    });

    return this;
  }

  static BASE_ITEM = {
    company: '',
  };
}
