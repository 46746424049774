import _ from 'lodash';
import { action, observable } from 'mobx';

import { SSMLogger, getChildLogger } from '#/shared/utils/client.logger';
import Uploader from '#/shared/utils/s3uploader';

export default class AssetUploader {
  constructor() {
    this.uploader = new Uploader({
      bucket: 'RETAIL',
      fileTypes: '.*',
      maxFileSize: 350,
    });
    this.files = [];
    this.results = [];
    this.uploadStatus = 'Ready';
    this.log = getChildLogger('AssetUploader');
  }

  log: SSMLogger;

  @observable uploader: Uploader = null;

  @observable files = [];

  @observable file = {};

  @observable uploadStatus = 'Ready';

  @observable progress = 0;

  @observable results = [];

  @observable filePicker = null;

  @action
  openFilePicker = (e) => {
    this.clear();

    this.filePicker.click(e);
  };

  @action
  selectFile = (file, ...rest) => {
    const fileObj = _.pick(file, ['name', 'lastModified', 'size', 'type']);
    this.files.push(fileObj);
    this.uploader.selectFile(file, ...rest);
  };

  @action
  setFilePicker = (cmp) => {
    this.filePicker = cmp;
  };

  @action
  onUploadStart = (file, next) => {
    this.uploadStatus = 'Pending';
    this.log.debug('Start: ', file);

    next(file);
  };

  @action
  onUploadProgress = (data) => {
    this.uploadStatus = 'Uploading';
    this.log.debug('Progress: ', data);
    this.progress = data;
  };

  @action
  onUploadError = (error) => {
    this.uploadStatus = 'Error';
    this.log.error('Error: ', error);
  };

  @action
  onUploadFinish = (data, file) => {
    this.uploadStatus = 'Complete';
    this.log.debug('Finish: ', data);

    const fileObj = _.find(this.files, {
      lastModified: file.lastModified,
      name: file.name,
      size: file.size,
    });

    const publicUrl = data.signedUrl.split('?')[0];
    _.extend(fileObj, data, { publicUrl });
    this.results.unshift(fileObj);
  };

  @action
  clear = (clearList?) => {
    this.uploadStatus = null;
    this.progress = 0;
    this.files = [];

    if (clearList) {
      this.results.clear();
    }
  };
}
