import type {
  ICompany,
  ISite,
  IUser,
  IZone,
} from '@shiftsmartinc/shiftsmart-types';

import { action, observable } from 'mobx';
import { dispatch } from 'rfx-core';
import moment, { MomentInput, unitOfTime } from 'moment';
import _ from 'lodash';

import { app } from '#/shared/app';
import { getDateRangeString } from '#/shared/components/utils/DateRangeString';
import { getZoneIdsFromTiers } from '#/shared/components/utils/LocationFilter/utils';
import { ILocationFilter } from '#/shared/components/utils/LocationFilter/ILocationFilter';

export default class Filters implements ILocationFilter {
  init() {
    app().on('login:company', ({ company, user }) => {
      this.resetCompanySpecificFields({ company, user });

      const locations = dispatch('auth.getManagedLocationIds');
      const csr = dispatch('auth.getCompanyStatus');

      if (
        _.includes(csr?.roles, 'site-manager') &&
        _.size(csr?.roles) <= 1 &&
        _.size(locations) === 1
      ) {
        this.setSiteIds(locations);
      }
    });
  }

  @observable
  siteIds = [];

  /**
   * ### showOnlySubscribedLocationsInFilters
   * When false, we will show all the locations for the agents, regardless the subscriptions
   * When true, only show the subscribed locations
   */
  @observable
  showOnlySubscribedLocationsInFilters = false;

  @observable
  isRemote = null;

  @observable
  selectedZoneIds: [
    IZone['uuid'],
    IZone['uuid'],
    IZone['uuid'],
    IZone['uuid'],
    IZone['uuid'],
  ] = [null, null, null, null, null];

  @observable
  startDateRange: null | {
    $gte: string;
    $lte: string;
  } = null;

  @observable
  selectedTimeRange: null | {
    $gte: string;
    $lte: string;
  } = null;

  preservedQueryStates = observable.map();

  @observable
  showAllShifts = true;

  getSiteIds() {
    return this.siteIds;
  }

  getIsRemote() {
    return this.isRemote;
  }

  getStartDateRange() {
    return this.startDateRange;
  }

  getFriendlyTimeString = ({
    start = this.selectedTimeRange?.$gte,
    roundBy = 'hour',
  }: { roundBy?: unitOfTime.Base; start?: MomentInput } = {}) =>
    getDateRangeString({
      start: !!start && (roundBy ? moment(start).startOf(roundBy) : start),
      timeFormat: 'LT',
    });

  getSelectedZoneIds() {
    return this.selectedZoneIds;
  }

  @action.bound
  setSiteIds(siteIds = []) {
    this.isRemote = false;
    this.siteIds = siteIds;
  }

  @action.bound
  setShowOnlySubscribedLocationsInFilters(
    showOnlySubscribedLocationsInFilters,
  ) {
    this.showOnlySubscribedLocationsInFilters =
      showOnlySubscribedLocationsInFilters;
  }

  @action.bound
  setIsRemote(isRemote) {
    this.isRemote = isRemote;
    if (isRemote) {
      this.siteIds = null;
    }
  }

  @action.bound
  setStartDateRange(startDateRange = null) {
    this.startDateRange = startDateRange;
  }

  @action.bound
  setSelectedTimeRange(
    selectedTimeRange,
    options?: { updateQueryParams?: boolean },
  ) {
    this.selectedTimeRange = selectedTimeRange;
    const shouldUpdateQueryParams =
      !_.isEmpty(selectedTimeRange) && options?.updateQueryParams;
    dispatch('routing.addQuery', {
      range: shouldUpdateQueryParams ? 'hour' : undefined,
      start: shouldUpdateQueryParams
        ? moment(this.selectedTimeRange.$gte).toISOString()
        : undefined,
    });
  }

  @action.bound
  resetCompanySpecificFields({
    user,
    company,
  }: {
    company: ICompany;
    user: IUser;
  } | null) {
    this.siteIds = [];
    this.preservedQueryStates.clear();

    if (!user || !company) {
      this.setShowOnlySubscribedLocationsInFilters(false);
    }

    // Set the showOnlySubscribedLocationsInFilters value according to the logged in user role
    const companyStatus = _.find(user.companyStatus, {
      company: company.uuid,
    });
    const isCompanyAgent = _.includes(companyStatus?.roles, 'agent');
    this.setShowOnlySubscribedLocationsInFilters(!isCompanyAgent);
  }

  @action.bound
  setSelectedZoneIds({
    selectedZone,
    tierIndex,
  }: {
    selectedZone?: ISite;
    tierIndex?: number;
  }) {
    this.selectedZoneIds = getZoneIdsFromTiers({
      company: dispatch('auth.getCompany'),
      selectedZone,
      selectedZoneIds: this.selectedZoneIds,
      tierIndex,
    });
  }

  @action.bound
  toggleShowAllShifts(val = !this.showAllShifts) {
    this.showAllShifts = val;
  }

  @action.bound
  clear() {
    this.resetCompanySpecificFields();
    this.startDateRange = null;
    this.selectedTimeRange = null;
  }
}
